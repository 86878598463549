import { IFile, useFileController } from 'packages/file-handling';
import { useState } from 'react';
import { downloadBase64File } from 'sollishealth.core.js';

export interface PreviewController {
  previewFileList: IFile[];
  displayFile: IFile;
  handleFileSelected: (file: IFile) => void;
  handleFileDownload: (downloadAll?: boolean) => void;
  clearPreview: () => void;
}

export interface PreviewControllerProps {
  previewFileList: IFile[];
  displayFile: IFile;
}

export const usePreviewController = (props: PreviewControllerProps): PreviewController => {
  const { clearPreview } = useFileController();
  const { previewFileList } = props;
  const [displayFile, setDisplayFile] = useState<IFile>(props.displayFile);

  const handleFileSelected = (file: IFile) => {
    setDisplayFile(file);
  };

  const handleFileDownload = (downloadAll: boolean = false) => {
    let filesToDownload = [displayFile!];
    if (downloadAll) {
      filesToDownload = previewFileList;
    }

    filesToDownload.forEach((file) =>
      downloadBase64File(file.fileName, file.contentBytes!, { document: window.document, mimeType: file.contentType })
    );
  };

  return {
    previewFileList,
    displayFile,
    handleFileDownload,
    handleFileSelected,
    clearPreview
  };
};

export default usePreviewController;
