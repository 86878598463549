import { Alert, AlertTitle, Stack } from '@mui/material';
import { loggedInUser } from 'hooks';
import { SuggestContactITLink } from 'components/ContactITLink';
import { SummaryTasksFilter } from './SummaryTasksFilter';
import SummaryTeamSelector from './SummaryTeamSelector';

const SummaryFilters: React.VFC = () => {
  const { hasTeams } = loggedInUser.useController();

  if (!hasTeams) {
    return (
      <Alert severity="error">
        <AlertTitle>You are not assigned to a team.</AlertTitle>
        <SuggestContactITLink />
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      <SummaryTeamSelector />
      <SummaryTasksFilter />
    </Stack>
  );
};

export default SummaryFilters;
