import { useMemo, useState } from 'react';
import { ITaskAssignedTo, ITeam } from 'types';
import { loggedInUser } from 'hooks';

export interface AssignToControllerProps {
  onSelect: (event: any) => void;
  currentOwner: ITaskAssignedTo | null;
  showAssignToMe: boolean;
  errors?: any;
  touched?: any;
  disabled?: boolean;
}

export interface AssignToController {
  currentOwner: ITaskAssignedTo | null;
  onSelect: (event: any) => void;
  handleAssignToMe: () => void;
  assignToOptions?: ITaskAssignedTo[];
  showOptions: boolean;
  showAssignToMe: boolean;
  errors?: any;
  touched?: any;
  disabled?: boolean;
  handleSearchClose: () => void;
}

export const useAssignTo = (props: AssignToControllerProps) => {
  const { userId, userName, myTeams } = loggedInUser.useController();
  const { currentOwner, onSelect = () => {}, showAssignToMe, touched, errors, disabled } = props;
  const [assignToOptions, setAssignToOptions] = useState<ITaskAssignedTo[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const assignToMeOptions = useMemo(
    () =>
      myTeams
        .filter((t) => t.id !== '0')
        .map((team: ITeam) => {
          return {
            displayName: `${team.displayName} - ${userName}`,
            groupId: team.id,
            userId: userId
          } as ITaskAssignedTo;
        }),
    [myTeams]
  );

  const handleSearchClose = () => {
    setShowOptions(false);
    setAssignToOptions([]);
  };

  const handleAssignToMe = () => {
    if (showAssignToMe) {
      if (myTeams.length > 1) {
        setAssignToOptions(assignToMeOptions);
        setShowOptions(true);
      } else if (myTeams.length === 1) {
        const syntheticOption = {
          displayName: `${myTeams[0].displayName} - ${userName}`,
          groupId: myTeams[0].id,
          userId: userId
        } as ITaskAssignedTo;
        const syntheticEvent = {
          target: {
            id: 'owner',
            value: {
              id: `${syntheticOption.groupId}${syntheticOption.userId}`,
              ...syntheticOption
            }
          }
        };
        onSelect(syntheticEvent);
      }
    } else {
      const syntheticOption = {
        displayName: `${currentOwner?.displayName.split(' - ')[0]}`,
        groupId: currentOwner?.groupId
      } as ITaskAssignedTo;
      const syntheticEvent = {
        target: {
          id: 'owner',
          value: {
            id: syntheticOption.groupId,
            ...syntheticOption
          }
        }
      };
      onSelect(syntheticEvent);
    }
  };

  return {
    currentOwner,
    onSelect,
    handleAssignToMe,
    assignToOptions,
    showOptions,
    showAssignToMe,
    errors,
    touched,
    disabled,
    handleSearchClose
  };
};

export default useAssignTo;
