import React from 'react';
import useSound from 'use-sound';
import { useSoundEffectsController } from './use-sound-effects-controller';

type Sound = string | string[];

const obstructionDelay = 1000; //  in milliseconds

/*
    Purpose: To respect browser mute setting(s)

    Usage:
    import mySound from './../my-sound.mp3'
    import { useSoundEffect } from './packages/sound-effects'

    const MyComponent = () => {
        const [ invokeSoundEffect ] = useSoundEffect(mySound)
        return (<button onClick={() => invokeSoundEffect()} >Play</button>)
    }
*/

export const useSoundEffect = (sound: Sound) => {
  //  We dont want to be bombarded by sound effects, so lets set a timeout to obstruct this sound if its done too much
  const [isObstructed, setObstruction] = React.useState<boolean>(false);
  const [playSoundEffect] = useSound(sound);
  const { isMuted } = useSoundEffectsController();

  //  handles removing obstruction if one is created
  React.useEffect(() => {
    if (isObstructed) {
      const timeout = setTimeout(() => {
        setObstruction(false);
      }, obstructionDelay);
      return () => clearTimeout(timeout);
    }
  }, [isObstructed]);

  const invokeSoundEffect = React.useCallback(() => {
    if (!isMuted && !isObstructed) {
      playSoundEffect();
      setObstruction(true);
    }
  }, [playSoundEffect, isMuted, isObstructed]);

  return [invokeSoundEffect];
};

export default useSoundEffect;
