import { createContextForController } from 'react-controller-context';
import { useTeamTasksCache } from '../../hooks/caches/use-team-tasks';
import { ITask, IUpdateTaskAssignedTo } from 'types';
import { useEffect, useMemo } from 'react';
import useSessionStorageState from 'use-session-storage-state';
import { loggedInUser } from 'hooks';

export enum DASHBOARD_SUMMARY_FILTER {
  USER = 'user',
  TEAM = 'team'
}

interface DashboardContext {
  isTasksLoading: boolean;
  isTasksRefreshing: boolean;
  hasTasks: boolean;
  myTasksCount: number;
  teamTasksCount: number;
  summaryTasks: ITask[];
  hasSummaryTasks: boolean;
  summaryFilter: DASHBOARD_SUMMARY_FILTER | null;
  dashboardTeamId: string;
  setDashbaordTeam: (teamId: string) => void;
  setSummaryFilter: (summaryFilter: DASHBOARD_SUMMARY_FILTER) => void;
  reloadTasks: () => void;
  handleUpdateAssignedTo: (assignedTo: IUpdateTaskAssignedTo) => Promise<void>;
}

export const useDashboardController = (): DashboardContext => {
  const { userId, hasTeams, myTeams } = loggedInUser.useController();

  const [dashboardTeamId, setDashbaordTeam] = useSessionStorageState<string>('dashboardTeamId', { defaultValue: '' });
  const {
    teamTasks,
    isTasksLoading,
    isTasksRefreshing,
    hasTasks,
    teamTasksCount,
    myTasksCount,
    handleUpdateAssignedTo,
    reloadTeamTasks,
    refreshTeamTasks
  } = useTeamTasksCache(dashboardTeamId);

  const [summaryFilterCache, setSummaryFilterCache] = useSessionStorageState<DASHBOARD_SUMMARY_FILTER | null>('summaryFilter', {
    defaultValue: null
  });

  const summaryTasks = useMemo(
    () =>
      !Boolean(summaryFilterCache)
        ? []
        : teamTasks.filter((t) => (summaryFilterCache === DASHBOARD_SUMMARY_FILTER.USER ? t.assignedToUserId === userId : true)),
    [teamTasks, summaryFilterCache]
  );
  const hasSummaryTasks = useMemo(() => Boolean(summaryTasks.length), [summaryTasks]);

  useEffect(() => {
    if (hasTeams && !Boolean(dashboardTeamId)) {
      let teamId = '';
      if (myTeams.length > 1) {
        teamId = 'ALL';
      } else {
        teamId = myTeams[0].id;
      }
      setDashbaordTeam(teamId);
    }
  }, [myTeams, hasTeams]);

  // When a team is selected, the summary view is cleared...
  // The default is User but if User has no tasks, it should swap to Team
  useEffect(() => {
    if (hasTasks && !Boolean(summaryFilterCache)) {
      let defaultSummaryFilter = DASHBOARD_SUMMARY_FILTER.USER;
      if (teamTasksCount > 0 && myTasksCount === 0) {
        defaultSummaryFilter = DASHBOARD_SUMMARY_FILTER.TEAM;
      }
      setSummaryFilterCache(defaultSummaryFilter);
    }
  }, [hasTasks, teamTasksCount, myTasksCount]);

  const reloadTasks = () => {
    reloadTeamTasks();
  };

  const handleDashboardTeam = (newTeamId: string) => {
    setDashbaordTeam(newTeamId);
    setSummaryFilterCache(null);
  };

  const handleSummaryFilter = (summaryFilter: DASHBOARD_SUMMARY_FILTER) => {
    // Refresh the query when changing task view
    refreshTeamTasks();
    setSummaryFilterCache(summaryFilter);
  };

  return {
    isTasksLoading,
    isTasksRefreshing,
    hasTasks,
    summaryTasks,
    hasSummaryTasks,
    summaryFilter: summaryFilterCache,
    myTasksCount,
    teamTasksCount,
    dashboardTeamId,
    reloadTasks,
    setSummaryFilter: handleSummaryFilter,
    setDashbaordTeam: handleDashboardTeam,
    handleUpdateAssignedTo
  };
};

const dashboardContext = createContextForController(useDashboardController);

export default dashboardContext;
