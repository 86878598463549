import { InteractionRequiredAuthError } from '@azure/msal-browser';

export async function getAuthToken(instance, account, scopes) {
  const accessTokenRequest = {
    scopes: scopes,
    account: account
  };

  try {
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
    const accessToken = accessTokenResponse.accessToken;
    return accessToken;
  } catch (error) {
    if (!(error instanceof InteractionRequiredAuthError)) {
      console.log(error);
      throw error;
    }
    const atr = await instance.acquireTokenPopup(accessTokenRequest);
    const at = atr.accessToken;
    return at;
  }
}

export function login(instance, state) {
  try {
    instance.loginRedirect({
      state: state
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}
