import MainCard from 'components/MainCard';
import { Grid, Typography } from '@mui/material';
import { loggedInUser } from 'hooks';
import TaskFilters from './components/TaskFilters';
import SummaryFilters from './components/SummaryFilters';

const Summary: React.VFC = () => {
  const { userName } = loggedInUser.useController();

  return (
    <MainCard
      contentSX={{ p: 2.25 }}
      title={
        <Grid container direction="row" sx={{ alignItems: 'center' }}>
          <Grid item xs sx={{ pl: 3 }}>
            <Typography variant="h4">Hello,</Typography>
            <Typography variant="h3">{userName}</Typography>
          </Grid>
          <Grid item xs="auto" textAlign="right">
            <SummaryFilters />
          </Grid>
        </Grid>
      }
    >
      <TaskFilters />
    </MainCard>
  );
};

export default Summary;
