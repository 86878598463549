import { lazy } from 'react';

// project import
import AppLayout from 'layout/AppLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Dashboard from 'views/Dashboard';
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import AutoLoginAuthGuard from 'utils/route-guard/AutoLoginAuthGuard';
import Task from 'views/Task';
import { medParterSearchRoute } from 'features/med-partner-search';
const AuthLogin = Loadable(lazy(() => import('views/Login')));

const Member = Loadable(lazy(() => import('views/Member')));
const AppRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '',
          element: <AuthLogin />
        },
        {
          path: 'login',
          element: <AuthLogin />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AutoLoginAuthGuard>
          <AppLayout />
        </AutoLoginAuthGuard>
      ),
      children: [
        {
          path: 'member/:memberId/:createMode/:taskId?',
          element: <Member />
        },
        {
          path: 'member/:memberId/task/:taskId',
          element: <Member />
        },
        {
          path: 'task/:taskId',
          element: <Task />
        },
        medParterSearchRoute
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'member',
          element: <Member />
        },
        {
          path: 'task',
          element: <Task />
        }
      ]
    }
  ]
};

export default AppRoutes;
