import {
  useTaskTypesCache,
  useTaskStatusesCache,
  useTaskPrioritiesCache,
  useTaskClosedReasonsCache,
  useTaskArchiveTimingsCache
} from 'hooks';
import { useMemo } from 'react';

export const useTaskLookupsCache = () => {
  const taskTypesCache = useTaskTypesCache();
  const taskStatusesCache = useTaskStatusesCache();
  const taskPrioritiesCache = useTaskPrioritiesCache();
  const closedReasonsCache = useTaskClosedReasonsCache();
  const taskArchiveTimingsCache = useTaskArchiveTimingsCache();

  const isCacheLoading = useMemo(
    () =>
      taskTypesCache.isTaskTypesLoading &&
      taskStatusesCache.isTaskStatusesLoading &&
      taskPrioritiesCache.isTaskPrioritiesLoading &&
      closedReasonsCache.isClosedReasonsLoading &&
      taskArchiveTimingsCache.isArchiveTimingsLoading,
    [
      taskTypesCache.isTaskTypesLoading,
      taskStatusesCache.isTaskStatusesLoading,
      taskPrioritiesCache.isTaskPrioritiesLoading,
      closedReasonsCache.isClosedReasonsLoading,
      taskArchiveTimingsCache.isArchiveTimingsLoading
    ]
  );

  const isCacheLoaded = useMemo(
    () =>
      taskTypesCache.hasTaskTypes &&
      taskStatusesCache.hasTaskStatuses &&
      taskPrioritiesCache.hasTaskPriorities &&
      closedReasonsCache.hasClosedReasons &&
      taskArchiveTimingsCache.hasArchiveTimings,
    [
      taskTypesCache.hasTaskTypes,
      taskStatusesCache.hasTaskStatuses,
      taskPrioritiesCache.hasTaskPriorities,
      closedReasonsCache.hasClosedReasons,
      taskArchiveTimingsCache.hasArchiveTimings
    ]
  );

  return {
    isCacheLoading,
    isCacheLoaded
  };
};
