import { ITaskConversation, conversationEnum } from 'types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { MILLISECONDS } from 'types';
import { useConversationService } from 'hooks';
import taskContext from '../../views/Member/useTaskContext';

interface ConversationCache {
  isLoading: boolean;
  isRefreshing: boolean;
  conversations: ITaskConversation[];
  hasConversations: boolean;
  createConversation: (conversation: ITaskConversation) => Promise<ITaskConversation>;
  updateConversationIdentifier: (conversation: ITaskConversation) => Promise<ITaskConversation>;
  deleteConversation: (conversation: ITaskConversation) => void;
  refreshConversations: () => void;
  handleResponse: (conversationId: number, taskParticipants?: string[]) => Promise<ITaskConversation>;
}

export const useConversationCache = (): ConversationCache => {
  const queryClient = useQueryClient();
  const conversationService = useConversationService();
  const { currentMemberTask } = taskContext.useController();

  const conversationQuery = useQuery({
    queryKey: ['conversations', currentMemberTask?.taskId],
    queryFn: () => conversationService.getTaskConversations(currentMemberTask?.taskId!),
    enabled: Boolean(currentMemberTask?.taskId),
    initialData: currentMemberTask?.conversations,
    refetchInterval: MILLISECONDS.MINUTE * 1
  });

  const createConversation = (conversation: ITaskConversation) => {
    return conversationService.createConversation(conversation).then(
      (conversationResponse: ITaskConversation) => conversationResponse,
      (err) => {
        refreshConversations();
        throw err;
      }
    );
  };

  const updateConversationIdentifier = (conversation: ITaskConversation) => {
    queryClient.setQueryData(['conversations', currentMemberTask?.taskId], (oldData: ITaskConversation[]) => {
      let currentConversations = oldData ?? [];
      let newConversations = [conversation, ...currentConversations];
      return newConversations;
    });

    return conversationService.updateConversationIdentifier(conversation);
  };

  const deleteConversation = (conversation: ITaskConversation) => {
    conversationService.deleteTaskConversation(conversation).then(() => {
      queryClient.setQueryData(['conversations', currentMemberTask?.taskId], (oldData: ITaskConversation[]) =>
        oldData.filter((c: ITaskConversation) => c.id !== conversation.id)
      );
    });
  };

  const refreshConversations = () => {
    queryClient.invalidateQueries({ queryKey: ['conversations', currentMemberTask?.taskId] });
  };

  const handleResponse = (conversationId: number, taskParticipants?: string[]) => {
    return conversationService.handleResponse({
      taskId: currentMemberTask?.taskId,
      id: conversationId,
      participants: taskParticipants
    });
  };

  const conversations = conversationQuery.data
    ? [...conversationQuery.data]
        .sort((a: ITaskConversation, b: ITaskConversation) => b.conversationTypeId! - a.conversationTypeId!)
        .sort((a: ITaskConversation, b: ITaskConversation) => a.id! - b.id!)
    : [];
  const isLoading = conversationQuery.isLoading;
  const isRefreshing = conversationQuery.isRefetching;
  const hasConversations = Boolean(conversations.filter((c) => c.conversationTypeId !== conversationEnum.CHAT).length);

  return {
    isLoading,
    isRefreshing,
    conversations,
    hasConversations,
    createConversation,
    updateConversationIdentifier,
    deleteConversation,
    refreshConversations,
    handleResponse
  };
};

export default useConversationCache;
