import { createSlice, current } from '@reduxjs/toolkit';
import { IMemberState, ITask } from 'types';

const initialState: IMemberState = {
  isMemberLoading: false,
  isMemberTaskLoading: false,
  isMemberTaskUpdating: false,
  member: null,
  currentMemberTask: null,
  currentMemberTaskAdditionalFields: []
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    isLoadingMember(state, action) {
      state.isMemberLoading = action.payload;
    },

    isLoadingMemberTask(state, action) {
      state.isMemberTaskLoading = action.payload;
    },

    isUpdatingMemberTask(state, action) {
      state.isMemberTaskUpdating = action.payload;
    },

    loadMember(state, action) {
      state.member = action.payload;
      state.isMemberLoading = false;
    },

    updateCurrentTaskParticipants(state, action) {
      const currentState = current(state);
      const updatedCurrentMemberTask = {
        ...currentState.currentMemberTask,
        participants: action.payload
      } as ITask;

      state.currentMemberTask = updatedCurrentMemberTask;
    },

    updateCurrentMemberTaskChatId(state, action) {
      const currentState = current(state);
      const updatedCurrentMemberTask = {
        ...currentState.currentMemberTask,
        chatId: action.payload
      } as ITask;

      state.currentMemberTask = updatedCurrentMemberTask;
    },

    updateCurrentMemberTask(state, action) {
      state.currentMemberTask = action.payload;
      state.isMemberTaskUpdating = false;
      state.isMemberTaskLoading = false;
    },

    setCurrentMemberTask(state, action) {
      state.currentMemberTask = action.payload;
      state.isMemberTaskLoading = false;
      state.isMemberTaskUpdating = false;
    },

    resetMember(state) {
      state.member = null;
    },

    resetMemberState(state) {
      return {
        ...initialState
      };
    }
  }
});

export default slice.reducer;
export const {
  isLoadingMember,
  isLoadingMemberTask,
  isUpdatingMemberTask,
  loadMember,
  setCurrentMemberTask,
  updateCurrentMemberTask,
  updateCurrentTaskParticipants,
  updateCurrentMemberTaskChatId,
  resetMember,
  resetMemberState
} = slice.actions;
