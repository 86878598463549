import { msalInstance } from 'contexts/Provider';
import { useIsAuthenticated } from '@azure/msal-react';
import { createContextForController } from 'react-controller-context';
import { useTeamsCache } from 'hooks';
import { useMemo } from 'react';

const useLoggedInUser = () => {
  const isLoggedIn = useIsAuthenticated();
  const accts = msalInstance.getAllAccounts();
  const userId = accts[0]?.idTokenClaims?.oid ?? '';
  const userName = accts[0]?.name ?? '';
  const userEmail = accts[0]?.username ?? '';
  const userGroups = (accts[0]?.idTokenClaims?.groups as string[]) ?? [];
  const userAccount = accts[0];

  const { teams, isTeamsLoading } = useTeamsCache();

  const myTeams = useMemo(() => teams.filter((t) => userGroups.includes(t.id)), [userGroups, teams]);
  const hasTeams = Boolean(myTeams.length);

  return {
    isLoggedIn,
    userId,
    userName,
    userEmail,
    userGroups,
    userAccount,
    myTeams,
    isMyTeamsLoading: isTeamsLoading,
    hasTeams
  };
};

export const loggedInUser = createContextForController(useLoggedInUser);
