import { api } from 'utils';
import { IRequestConfig, ITask, IMember } from 'types';
import { dispatch } from 'store';
import { isLoadingMember, isLoadingMemberTask, loadMember, setCurrentMemberTask } from './member.slice';
import { parsePhoneNumber } from 'libphonenumber-js';

function fetchData(url: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const onResolve = (res: any) => {
      resolve(res.data);
    };

    const onReject = (err: unknown) => {
      reject(err);
    };

    const config: IRequestConfig = {
      method: 'get',
      url: url,
      onResolve,
      onReject
    };
    api.processRequest(config);
  });
}

export function member_loadMemberTask(taskId: string, loadMember: boolean) {
  dispatch(isLoadingMemberTask(true));
  return fetchData(`tasks/v1/Task/${taskId}`)
    .then((taskResponse) => {
      const task: ITask = taskResponse;

      dispatch(setCurrentMemberTask(task));

      if (loadMember) {
        return member_loadMember(task.memberId).then((memberId) => {
          return memberId;
        });
      } else {
        return;
      }
    })
    .catch((error) => {
      dispatch(isLoadingMemberTask(false));
      console.error('Error fetching task details:', error);
      throw error;
    });
}

export function member_loadMember(memberId: number) {
  dispatch(isLoadingMember(true));
  return fetchData(`member/v1/${memberId}`).then(
    (member: IMember) => {
      const loadedMember = {
        ...member,
        shortName: `${member?.firstName} ${member?.lastName?.substring(0, 1)}`,
        formattedMobilePhoneNumber: Boolean(member.cleanMobilePhoneNumber)
          ? parsePhoneNumber(member?.cleanMobilePhoneNumber!, 'US')?.formatNational()
          : undefined,
        formattedPrimaryPhoneNumber: Boolean(member.cleanPrimaryPhoneNumber)
          ? parsePhoneNumber(member?.cleanPrimaryPhoneNumber!, 'US')?.formatNational()
          : undefined
      };
      dispatch(loadMember(loadedMember));
      return member.memberId;
    },
    () => dispatch(isLoadingMember(false))
  );
}
