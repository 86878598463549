import { Button, Grid, useTheme } from '@mui/material';
import { useCurrentWidth } from 'hooks';
import dashboardContext, { DASHBOARD_SUMMARY_FILTER } from 'views/Dashboard/useDashboardContext';

interface SummaryTaskButtonsProps {
  isActive: boolean;
  buttonStyle: any;
  onButtonClicked: () => void;
  label: string;
}

const SummaryTaskButtons = (props: SummaryTaskButtonsProps) => {
  const { isActive, buttonStyle, onButtonClicked, label } = props;

  const browserWidth = useCurrentWidth();
  const theme = useTheme();
  const sx = { backgroundColor: theme.palette.secondary[800] };
  const xsBreakpoint = theme.breakpoints.values.md;
  const xsButtons = browserWidth <= xsBreakpoint;

  return (
    <Button
      variant={isActive ? 'contained' : 'outlined'}
      style={buttonStyle}
      sx={isActive ? sx : null}
      color="secondary"
      size={xsButtons ? 'extraSmall' : 'small'}
      onClick={onButtonClicked}
    >
      {label}
    </Button>
  );
};

export const SummaryTasksFilter = () => {
  const { myTasksCount, teamTasksCount, summaryFilter, setSummaryFilter } = dashboardContext.useController();

  return (
    <Grid container>
      <Grid item xs={12}>
        <SummaryTaskButtons
          isActive={summaryFilter === DASHBOARD_SUMMARY_FILTER.USER}
          buttonStyle={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
          onButtonClicked={() => setSummaryFilter(DASHBOARD_SUMMARY_FILTER.USER)}
          label={`My Tasks ${!summaryFilter ? '' : '(' + myTasksCount + ')'}`}
        />
        <SummaryTaskButtons
          isActive={summaryFilter === DASHBOARD_SUMMARY_FILTER.TEAM}
          buttonStyle={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
          onButtonClicked={() => setSummaryFilter(DASHBOARD_SUMMARY_FILTER.TEAM)}
          label={`Team Tasks ${!summaryFilter ? '' : '(' + teamTasksCount + ')'}`}
        />
      </Grid>
    </Grid>
  );
};
