import { IMedicalPartner } from 'types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { uniq } from 'lodash';

type Specialty = IMedicalPartner['primarySpecialty'] | IMedicalPartner['otherSpecialty'];

const SpecialityChip = ({ specialty }: {specialty: Specialty }) => {
    return !specialty ? null : (
        <Chip label={specialty} variant="outlined" size="small" />
    );
};

export const SpecialityChips = ({ record }: {record: IMedicalPartner}) => {
    const specialties = [ record.primarySpecialty, record.otherSpecialty ];
    const renderSpecialty = (specialty: Specialty, key: number) => (
        <SpecialityChip key={key} specialty={specialty} />
    );
    return (
        <Stack direction="row" spacing={1} useFlexGap>
            {uniq(specialties).map(renderSpecialty)}
        </Stack>
    );
};