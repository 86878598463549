import { Box, Chip, Popover } from '@mui/material';
import { useState } from 'react';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const useFilterDate = (onChange: (o: FilterDate | null) => void) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterChoice = (date: Moment | null) => {
    let filterDateObject: FilterDate | null = null;
    if (date) {
      const startOfDay = date.clone().startOf('day');
      const endOfDay = date.clone().endOf('day');
      const utcStartDate = moment.utc(startOfDay).format();
      const utcEndDate = moment.utc(endOfDay).format();
      filterDateObject = {
        date,
        dateRange: [utcStartDate, utcEndDate]
      };
    }
    onChange(filterDateObject);
    setAnchorEl(null);
  };

  const clearFilter = () => {
    onChange(null);
    setAnchorEl(null);
  };

  const showPicker = (event: any | null) => {
    setAnchorEl(event.currentTarget);
  };

  const hidePicker = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleFilterChoice,
    clearFilter,
    showPicker,
    hidePicker
  };
};

export interface FilterDate {
  date: Moment | null;
  dateRange: string[];
}

interface FilterFilterDateProps {
  currentDate: Moment | null;
  filterName: string;
  onChange: (o: FilterDate | null) => void;
}

export const FilterDatePicker = (props: FilterFilterDateProps) => {
  const { currentDate, filterName, onChange } = props;
  const { anchorEl, handleFilterChoice, clearFilter, showPicker, hidePicker } = useFilterDate(onChange);

  return (
    <Box style={{ flex: 1, position: 'relative' }}>
      <Chip
        size={Boolean(currentDate) ? 'medium' : 'small'}
        variant={Boolean(currentDate) ? 'outlined' : undefined}
        label={Boolean(currentDate) ? currentDate!.format('ddd, MMM Do YYYY') : filterName}
        icon={Boolean(currentDate) ? <FilterAltRoundedIcon /> : <FilterAltOffRoundedIcon />}
        onClick={(e) => (Boolean(anchorEl) ? hidePicker() : showPicker(e))}
        onDelete={Boolean(currentDate) ? () => clearFilter() : undefined}
        deleteIcon={Boolean(currentDate) ? <ClearRoundedIcon /> : undefined}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={hidePicker}
        slotProps={{
          paper: {
            onMouseLeave: hidePicker
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateCalendar disableFuture value={currentDate} onChange={handleFilterChoice} />
        </LocalizationProvider>
      </Popover>
    </Box>
  );
};
