import { IMemberMessage, IMessageRequest } from 'types';
import ApiService from './api-service';

export class MessageService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getConversationMessages(conversationId: string): Promise<IMemberMessage[]> {
    return this.apiService.get(`messaging/v1/Conversation/${conversationId}/messages`).then((r) => r?.data);
  }

  sendMessage(message: IMessageRequest): Promise<IMemberMessage> {
    return this.apiService.post(`messaging/v1/Conversation/messages`, message).then((r) => r?.data);
  }

  downloadMessageAttachment(filePath: string): Promise<string> {
    return this.apiService.post(`messaging/v1/Conversation/downloadAttachment?filePath=${encodeURI(filePath)}`, {}).then((r) => r?.data);
  }
}

export default MessageService;
