import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { taskPriorityEnum } from 'types/store/taskEnums';
import { highlightColorEnum } from 'components/TextHighlight';

interface ApexPriorityChartProps {
  priorityCounts: number[];
  onPrioritySelected: (priorityStatus: taskPriorityEnum) => void;
}

const ApexPriorityChart: React.VFC<ApexPriorityChartProps> = (props) => {
  const { priorityCounts, onPrioritySelected } = props;
  const theme = useTheme();
  const { primary } = theme.palette.text;
  const line = theme.palette.divider;
  const backColor = theme.palette.background.paper;
  const priorityRoutine = highlightColorEnum.Routine;
  const priorityHigh = highlightColorEnum.High;
  const priorityEmergency = highlightColorEnum.Emergency;

  const setGridViewFilters = (idx: number) => {
    switch (idx) {
      case 0:
        onPrioritySelected(taskPriorityEnum.Routine);
        break;
      case 1:
        onPrioritySelected(taskPriorityEnum.High);
        break;
      default:
        onPrioritySelected(taskPriorityEnum.Emergency);
    }
  };

  const options: ChartProps = useMemo(() => {
    return {
      labels: ['Routine', 'High', 'Emergency'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '20px',
          fontWeight: 'bolder'
        },
        formatter: (_val: number, opts: any) => {
          const { seriesIndex, w } = opts;
          return w.globals.seriesTotals[seriesIndex];
        }
      },
      chart: {
        type: 'donut',
        width: 200,
        height: 200,
        events: {
          dataPointSelection: (_event: any, _chartContext: any, seriesIndex: any) => {
            const idx = seriesIndex.dataPointIndex;
            setTimeout(() => setGridViewFilters(idx), 200);
          },
          dataPointMouseEnter: (event: any) => {
            event.target.style.cursor = 'pointer';
          }
        }
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 800,
                fontSize: '2.0rem'
              },
              value: {
                show: true,
                fontWeight: 800,
                fontSize: '1.5rem'
              },
              total: {
                show: true,
                fontSize: '1.25rem'
              }
            }
          }
        }
      },
      colors: [priorityRoutine, priorityHigh, priorityEmergency],
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary, primary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [primary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      legend: false,
      stroke: {
        colors: [backColor]
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backColor, line, primary, priorityCounts, setGridViewFilters, priorityRoutine, priorityHigh, priorityEmergency]);

  return <ReactApexChart options={options} series={priorityCounts} type="donut" />;
};

export default ApexPriorityChart;
