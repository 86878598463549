import { Typography, Grid } from '@mui/material';
import MainCard from 'components/MainCard';
import { SearchFilterActivity } from './components';
import { RecentActivityList } from './components/RecentActivityList';
import { NoActivityIndicator } from './no-activity-indicator';
import { ActivityLoading } from './activity-loading';

const RecentActivity = () => {
  return (
    <MainCard
      contentSX={{ p: 2.25 }}
      title={
        <Grid container>
          <Grid item xs>
            <Typography variant="h5">Recent Activity</Typography>
          </Grid>
        </Grid>
      }
      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'start', position: 'relative' }}
    >
      <>
        <SearchFilterActivity />
        <MainCard sx={{ display: 'flex', minHeight: '565px', maxHeight: '565px', overflowY: 'auto' }} id="activityScroller" content={false}>
          <RecentActivityList />
          <NoActivityIndicator />
          <ActivityLoading />
        </MainCard>
      </>
    </MainCard>
  );
};

export default RecentActivity;
