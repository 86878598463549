import { Button } from '@mui/material';
import { useAssignToProvider } from './useAssignToProvider';

const AssignToButton: React.VFC = () => {
  const { showAssignToMe, disabled, handleAssignToMe } = useAssignToProvider();

  if (disabled) {
    return null;
  }

  return (
    <Button variant="text" onClick={handleAssignToMe}>
      {showAssignToMe ? 'Assign To Me' : 'Assign Back to Team'}
    </Button>
  );
};

export default AssignToButton;
