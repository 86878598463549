import appSettings from '../constants/appsettings.json';
import React from 'react';

interface IAppFeatures {
  [key: string]: boolean;
}

export enum Environment {
  DEV = 'dev',
  STAGE = 'stage',
  PRODUCTION = 'prod'
}

//  List of environments we need to evaluate!
export const knownEnvironments = [Environment.DEV, Environment.STAGE, Environment.PRODUCTION];

export const useEnvironment = () => {
  const features = appSettings.features as IAppFeatures;

  const environment = React.useMemo(
    () => knownEnvironments.reduce((lE, e) => (appSettings.environment === e ? e : lE), Environment.PRODUCTION),
    [appSettings]
  );

  const isProd = environment === Environment.PRODUCTION;

  return {
    isProd,
    features,
    environment
  };
};
