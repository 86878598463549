import { Snackbar } from '@mui/material';
import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { dispatch, clearToast, useSelector } from 'store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ActionSnackBar: React.VFC = () => {
  const { snackState } = useSelector((state) => state.root);

  if (!snackState.open) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={snackState.open}
      autoHideDuration={5000}
      onClose={() => dispatch(clearToast())}
      key="bottomright"
    >
      <Alert onClose={() => dispatch(clearToast())} severity={snackState.isSuccess ? 'success' : 'error'}>
        {snackState.message ? snackState.message : snackState.isSuccess ? 'Changes Saved' : 'Changes Not Saved'}
      </Alert>
    </Snackbar>
  );
};

export default ActionSnackBar;
