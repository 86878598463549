import * as React from 'react';
import { useSearchFilters } from './search-filters-provider';
import {
    FormLabel
} from '@mui/material';
import { MultiObjectSelector } from '../../../components/MultiObjectSelector';
import { SearchFilterField } from '../definitions';

interface Props {
    label: string;
    field: SearchFilterField;
    wrapper?: React.FC<React.PropsWithChildren>;
}

export const MultiSelectorInput = ({
    label,
    field,
    wrapper: Wrapper = ({ children }: React.PropsWithChildren) => (<>{children}</>)
}: Props) => {
    const { getValue, setValue, getOptions, getOptionLabel, getOptionValue } = useSearchFilters();
 
    return (
      <Wrapper>
        <FormLabel>{label}</FormLabel>
        <MultiObjectSelector
          fullWidth={true}
          onGetLabel={getOptionLabel}
          onGetValue={getOptionValue}
          options={getOptions(field)}
          values={getValue(field) as string[] || []}
          onChange={v => setValue(field, v)}
        />
      </Wrapper>
    );
};
