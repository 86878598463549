import { createSlice } from '@reduxjs/toolkit';
import { IAppointmentState } from 'types';

const initialAppointmentState: IAppointmentState = {
  isLoadingQuestions: false,
  isLoadingReasons: false,
  isLoadingLocationStates: false,
  isLoadingTimeSlots: false,
  questions: [],
  types: [],
  locationStates: [],
  locations: [],
  timeSlots: []
};

const slice = createSlice({
  name: 'appointments',
  initialState: initialAppointmentState,
  reducers: {
    isLoadingQuestions(state, action) {
      return {
        ...state,
        isLoadingQuestions: action.payload
      };
    },
    loadQuestions(state, action) {
      return {
        ...state,
        isLoadingQuestions: false,
        questions: action.payload
      };
    },
    isLoadingReasons(state, action) {
      return {
        ...state,
        isLoadingReasons: action.payload
      };
    },
    loadReasons(state, action) {
      return {
        ...state,
        isLoadingReasons: false,
        types: action.payload
      };
    },
    isLoadingLocationStates(state, action) {
      return {
        ...state,
        isLoadingLocationStates: action.payload
      };
    },
    loadLocationStates(state, action) {
      return {
        ...state,
        isLoadingLocationStates: false,
        locationStates: action.payload
      };
    },
    loadLocations(state, action) {
      return {
        ...state,
        locations: action.payload
      };
    },
    isLoadingTimeSlots(state, action) {
      return {
        ...state,
        isLoadingTimeSlots: action.payload
      };
    },
    loadTimeSlots(state, action) {
      return {
        ...state,
        isLoadingTimeSlots: false,
        timeSlots: action.payload
      };
    },
    clearTimeSlots(state) {
      return {
        ...state,
        timeSlots: []
      };
    },
    resetAppointmentState(state) {
      return {
        ...initialAppointmentState
      };
    }
  }
});

export default slice.reducer;
export const {
  isLoadingQuestions,
  loadQuestions,
  isLoadingReasons,
  loadReasons,
  isLoadingLocationStates,
  loadLocationStates,
  loadLocations,
  isLoadingTimeSlots,
  loadTimeSlots,
  clearTimeSlots,
  resetAppointmentState
} = slice.actions;
