import { SearchAndShowController, SearchAndShowProps, SearchOption } from './definitions';
import { useState } from 'react';
import { useSearchString } from './search-string-provider';

export const useSearchAndShowController = (props: SearchAndShowProps): SearchAndShowController => {
  const {
    id,
    showLoading,
    placeHolder,
    value = null,
    isDisabled,
    allowFreeText,
    allowMultiple,
    showOptions = false,
    defaultOptions = [],
    searchOptions,
    errors,
    touched,
    onClose = () => {},
    onSelect,
    renderTags
  } = props;
  const { debouncedSearchString, setSearchString, searchString } = useSearchString();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const highlightSearchValue = searchString?.trim();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const onChange = (event: any, newValue: SearchOption | SearchOption[]) => {
    if (!event) {
      return;
    }

    let eventValue = undefined;

    if (!newValue) {
      if (allowMultiple) {
        eventValue = [];
      } else if (allowFreeText) {
        eventValue = '';
      } else {
        eventValue = null;
      }
    } else {
      if (Array.isArray(newValue)) {
        eventValue = newValue.map((o) => o.optionObject);
      } else if (allowFreeText) {
        if (typeof newValue === 'string') {
          eventValue = newValue;
        } else {
          eventValue = newValue.optionObject.fullName ?? newValue.optionObject;
        }
      } else {
        eventValue = newValue.optionObject;
      }
    }

    const newEvent = {
      ...event,
      id: id,
      target: {
        ...event.target,
        value: eventValue,
        id: id
      }
    };
    setSearchString('');
    onSelect(newEvent);
  };

  const onInputChange = (event: any, newInputValue: any) => {
    if (!event) {
      return;
    }
    setSearchString(newInputValue);
  };

  const noOptionsText =
    searchOptions === undefined || !searchString || debouncedSearchString.length <= 2
      ? `${!searchString ? 'Start' : 'Continue'} typing to search...`
      : !showLoading && searchOptions.length === 0
      ? 'No results matched search.'
      : '';

  let options = [...(searchOptions ?? [])];
  if (!searchString) {
    options = [...defaultOptions];
  }

  const filterOptions = allowFreeText
    ? (options: any, params: any) => {
        let filtered = [...options];
        const { inputValue } = params;
        if (inputValue !== '' && inputValue.length > 2 && !showLoading) {
          filtered.push({
            displayName: inputValue,
            optionObject: inputValue
          } as SearchOption);
        }
        return filtered;
      }
    : (options: any, _params: any) => options;
  const filterSelectedOptions = allowMultiple ? true : undefined;
  const showOpen = isOpen || showOptions;

  return {
    id,
    placeHolder,
    value,
    isDisabled,
    isSearching: showLoading,
    allowFreeText,
    allowMultiple,
    showOpen,
    noOptionsText,
    options,
    filterSelectedOptions,
    filterOptions,
    highlightSearchValue,
    errors,
    touched,
    handleOpen,
    handleClose,
    onChange,
    onInputChange,
    renderTags
  };
};

export default useSearchAndShowController;
