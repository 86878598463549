import { SearchGroupUsers } from 'packages/search-and-show';
import { useAssignToProvider } from './useAssignToProvider';

const AssignToSelector: React.VFC = () => {
  const { currentOwner, onSelect, disabled, showOptions, assignToOptions, handleSearchClose, errors, touched } = useAssignToProvider();

  return (
    <SearchGroupUsers
      id="owner"
      value={currentOwner}
      disabled={disabled}
      onSelect={onSelect}
      showOptions={showOptions}
      defaultOptions={assignToOptions}
      onClose={handleSearchClose}
      errors={errors}
      touched={touched}
    />
  );
};

export default AssignToSelector;
