import { loggedInUser, useTaskService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useTaskTypesCache = () => {
  const taskService = useTaskService();
  const { isLoggedIn } = loggedInUser.useController();

  const query = useQuery({
    queryKey: ['taskTypes'],
    queryFn: () => taskService.getTaskTypes(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isTaskTypesLoading, data: taskTypes = [] } = query;

  return {
    isTaskTypesLoading,
    taskTypes,
    hasTaskTypes: taskTypes.length > 0
  };
};
