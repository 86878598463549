import { useQuery } from '@tanstack/react-query';
import { useMemberService } from 'hooks';
import { MILLISECONDS, IFeedback } from 'types';
import { useSelector } from 'store';

interface MemberFeedbackCache {
  memberFeedback: IFeedback[];
  isLoading: boolean;
  hasFeedback: boolean;
}

export const useMemberFeedbackCache = (): MemberFeedbackCache => {
  const { member } = useSelector((state) => state.member);
  const memberService = useMemberService();

  const memberFeedbackQuery = useQuery({
    queryKey: ['memberFeedback', member?.memberId],
    queryFn: () => memberService.getMemberFeedback(member?.memberId!),
    enabled: Boolean(member?.memberId),
    refetchInterval: MILLISECONDS.MINUTE * 3
  });

  const memberFeedback = memberFeedbackQuery.data
    ? memberFeedbackQuery.data.map((f) => {
        return {
          ...f,
          deptCat: f.department,
          source: 'Salesforce'
        } as IFeedback;
      })
    : [];
  const isLoading = memberFeedbackQuery.isLoading;
  const hasFeedback = Boolean(memberFeedback.length);

  return {
    memberFeedback,
    isLoading,
    hasFeedback
  };
};
