import axios from 'axios';
import { Dispatch, AnyAction } from 'redux';
import { setIsUIVersionOutOfDate } from 'store';
import compareVersions from './helpers/compareVersions';

const CURRENT_VERSION = process.env.REACT_APP_VERSION || '0.0.0';

export const createAxiosInstance = (
  dispatch: Dispatch<AnyAction>,
  baseURL: string,
  authToken: string,
  correlationId: string,
  messageId: string
) => {
  const axiosServices = axios.create({
    baseURL,
    timeout: 45000,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken}`,
      CorrelationId: correlationId,
      MessageId: messageId
    }
  });

  axiosServices.interceptors.response.use(
    (response) => {
      const version = response.headers['x-sollisversion'];

      if (version && compareVersions(version, CURRENT_VERSION)) {
        dispatch(setIsUIVersionOutOfDate(true));
      }
      return response;
    },

    (error) => Promise.reject(error)
  );

  return axiosServices;
};
