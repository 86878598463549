// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery, Grid, Typography } from '@mui/material';
import GlobalSearch from './Search/GlobalSearch';
import { useEnvironment } from 'hooks';

// project import
import Profile from './Profile';
import Notification from './Notification';
import { MuteButton } from './MuteButton';
import globalSearchContext from './Search/use-global-search';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { isProd } = useEnvironment();
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item marginLeft={6}>
        <globalSearchContext.Provider>
          <GlobalSearch />
        </globalSearchContext.Provider>
      </Grid>
      {!isProd && (
        <Grid item alignSelf={'center'}>
          <Typography flexWrap={'wrap'} textAlign={'center'} variant="h3">
            *** TEST ENVIRONMENT ***
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {matchesXs && (
            <Grid item>
              <Box sx={{ width: '100%', ml: 1 }} />
            </Grid>
          )}
          <Grid item>
            <MuteButton />
          </Grid>
          <Grid item>
            <Notification />
          </Grid>
          {!matchesXs && (
            <Grid item>
              <Profile />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderContent;
