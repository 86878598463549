import { IFile } from 'packages/file-handling';
import { IMemberContact } from './member';

export interface ICommunication {
  id?: string;
  repliedToId?: string;
  subject: string;
  toRecipients?: string;
  toName?: string;
  ccRecipients?: string;
  bccRecipients?: string;
  fromRecipient: string;
  fromName: string;
  draftSavedBy?: string;
  date?: string;
  content?: string;
  files?: ICommunicationFile[];
  isDraft?: boolean;
  type: CONVERSATION_TYPE;
  contactId?: IMemberContact['contactId'];
}

export interface ICommunicationFile extends IFile {
  fileId?: string;
  filePath?: string;
}

export enum CONVERSATION_TYPE {
  EMAIL = 'Email',
  MEMBER_PORTAL = 'Member Portal',
  SMS = 'SMS',
  SYSTEM_EMAIL = 'System Email',
  CHAT = 'Chat'
}

export enum conversationEnum {
  EMAIL = 1,
  MEMBER_PORTAL = 2,
  SMS = 3,
  SYSTEM_EMAIL = 4,
  CHAT = 5
}

export interface ITaskConversation {
  id?: number;
  taskId?: string;
  conversationTypeId?: number;
  conversationIdentifier?: string;
  internalSource?: string;
  internalName?: string;
  externalSource?: string;
  externalName?: string;
  isActive?: boolean;
  hasNewReply?: boolean;
  participants?: string[];
}

export interface ITaskConversationType {
  id?: number;
  description?: string;
  allowDraft?: boolean;
  allowMultiples?: boolean;
}
