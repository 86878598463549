import { ReactNode } from 'react';
import ShowMoreText from 'react-show-more-text';

interface Props {
  textContent?: string | ReactNode;
  showLines?: number;
  expanded?: boolean;
}

export const ShowMoreTextContent = (props: Props) => {
  const { textContent, showLines = 3, expanded = false } = props;
  return (
    <ShowMoreText lines={showLines} anchorClass="text-show-more" expanded={expanded}>
      {textContent}
    </ShowMoreText>
  );
};

export default ShowMoreTextContent;
