export default function compareVersions(version1: string, version2: string): boolean {
  const version1Parts = version1.split('.').map(Number);
  const version2Parts = version2.split('.').map(Number);
  const maxLength = Math.max(version1Parts.length, version2Parts.length);

  for (let i = 0; i < maxLength; i++) {
    const v1 = version1Parts[i] || 0;
    const v2 = version2Parts[i] || 0;

    if (v1 > v2) {
      return true;
    } else if (v1 < v2) {
      return false;
    }
  }

  // If all parts are equal, return false
  return false;
}
