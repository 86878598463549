import { MenuItem, TextField, TextFieldProps } from '@mui/material';

export interface TextFieldObjectSelectorProps<Type> {
  options?: Type[];
  value?: Type;
  onChange?: (v: Type) => void;
  onGetLabel?: (v: Type) => any;
  placeholder?: string;
  disabled?: boolean;
  id?: TextFieldProps['id'];
  name?: TextFieldProps['name'];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  fullWidth?: TextFieldProps['fullWidth'];
}

export const TextFieldObjectSelector = <Type extends unknown>({
  options = [],
  value: inputValue,
  onChange = () => {},
  onGetLabel,
  placeholder = '',
  disabled,
  id,
  name,
  error,
  helperText,
  fullWidth
}: TextFieldObjectSelectorProps<Type>) => {
  const value = inputValue ? options.indexOf(inputValue) : '' || '';

  return (
    <TextField
      select
      onChange={(e: any) => onChange(options[e.target.value])}
      value={value}
      id={id}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
    >
      {options.map((o, index) => (
        <MenuItem key={index} value={index}>
          {(onGetLabel && onGetLabel(o)) || index}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default TextFieldObjectSelector;
