import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const EmptyFilter: React.VFC<{ label: string }> = ({ label }) => {
  const theme = useTheme();

  const donutContainerStyle = { width: '100%', height: '100%' };

  const donutStyle = {
    width: '57%',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary[200],
    aspectRatio: '1/1',
    margin: '9.9% auto'
  };

  const donutTextStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    verticalAlign: 'middle'
  };

  return (
    <Box style={donutContainerStyle}>
      <Typography variant="h5">{label}</Typography>
      <Box style={donutStyle}>
        <Box style={donutTextStyle}>
          <Typography variant="body1" style={{ margin: 'auto' }}>
            No Tasks
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyFilter;
