import { useSelector } from 'react-redux';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { RootStateProps } from 'types/root';

export interface DrawerButtonProps {
    onClick?: () => void;
    label: string;
    iconComponent: React.FC;
  }
  
export const DrawerButton = ({
    onClick = () => {},
    label,
    iconComponent: Icon
}: DrawerButtonProps) => {
    const drawerOpen = useSelector((state: RootStateProps) => state?.menu?.drawerOpen);
    return (
      <ListItem>
        <ListItemButton
          onClick={onClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: drawerOpen ? 3 : 'auto',
              justifyContent: 'center'
            }}
          >
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} sx={{ opacity: drawerOpen ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    );
};