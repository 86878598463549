import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface IUserAvatarProps {
  displayName: string;
  size?: string;
}

const UserAvatar: React.VFC<IUserAvatarProps> = (props) => {
  const theme = useTheme();
  const { displayName, size = 'lg' } = props;

  let iconStyle = {};
  switch (size) {
    case 'sm':
      iconStyle = { fontSize: '0.8rem', width: '25px', height: '25px' };
      break;
    case 'md':
      iconStyle = { fontSize: '1.0rem', width: '30px', height: '30px' };
      break;
    default:
      iconStyle = {};
  }

  const avatarDisplay = !Boolean(displayName)
    ? '??'
    : displayName.split(' ').reduce((a, v) => (a = a + v.substring(0, 1).toUpperCase()), '');

  let backgroundColor = '';
  const lnInitial = avatarDisplay.slice(-1);
  if (displayName === 'Member' || displayName === 'Navigator' || avatarDisplay === '??') {
    backgroundColor = theme.palette.secondary.main;
    if (avatarDisplay === '??') {
      backgroundColor = theme.palette.secondary.dark;
    }
  } else if ('A' <= lnInitial && lnInitial <= 'G') {
    backgroundColor = theme.palette.primary.main;
  } else if ('H' <= lnInitial && lnInitial <= 'M') {
    backgroundColor = theme.palette.secondary.main;
  } else if ('N' <= lnInitial && lnInitial <= 'S') {
    backgroundColor = theme.palette.warning.main;
  } else if ('T' <= lnInitial && lnInitial <= 'Z') {
    backgroundColor = theme.palette.error.main;
  }

  const sx = { ...iconStyle, backgroundColor, margin: '2px' };

  return (
    <div title={displayName ?? 'unknown user'}>
      <Avatar alt={displayName ?? 'unknown user'} sx={sx}>
        {avatarDisplay}
      </Avatar>
    </div>
  );
};

export default UserAvatar;
