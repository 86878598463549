import { Button } from '@mui/material';
import { usePreviewProvider } from './use-preview-provider';

const DownloadAllButton = () => {
  const { handleFileDownload, previewFileList } = usePreviewProvider();

  return previewFileList.length === 1 ? null : (
    <Button
      variant="contained"
      sx={{ margin: '10px', backgroundColor: '#9AA4B2', color: '#ffffff' }}
      type="button"
      onClick={() => handleFileDownload(true)}
    >
      Download All
    </Button>
  );
};

export default DownloadAllButton;
