import { IChatRequest, IChatMessage } from 'types';
import ApiService from './api-service';

export class ChatService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getChatMessages(chatId: string): Promise<IChatMessage[]> {
    return this.apiService.get(`chat/v1/${chatId}/chatMessages`).then((r) => r?.data);
  }

  createChatMessage(chatRequest: IChatRequest): Promise<IChatMessage[]> {
    return this.apiService.post('chat/v1/chatMessage', chatRequest).then((r) => r?.data);
  }

  getChatTopic(chatId: string): Promise<string> {
    return this.apiService.get(`chat/v1/${chatId}`).then((r) => r?.data);
  }

  clearChats(): Promise<void> {
    return this.apiService.post(`tasks/v1/Task/cleanupTaskChats`, { maxChatCount: 20 });
  }
}

export default ChatService;
