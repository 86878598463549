import { SearchForm } from '../layouts/search-form';
import { SearchIcon } from './search-icon';
import { useSearchMode } from './search-mode-provider';

export const TopSection = () => {
    const { isFormulatingSearch } = useSearchMode();
    return isFormulatingSearch ? (
        <SearchForm />
    ) : (
        <SearchIcon />
    );
};
