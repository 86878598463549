// ==============================|| OVERRIDES - LINK ||============================== //

import { LinkProps } from '@mui/material/Link';

export default function Link() {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover' as LinkProps['underline']
      }
    }
  };
}
