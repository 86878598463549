import { createSlice } from '@reduxjs/toolkit';
import { IEncounterState } from 'types';

const initialState: IEncounterState = {
  isLoading: false,
  encounters: []
};

const slice = createSlice({
  name: 'encounter',
  initialState,
  reducers: {
    isLoadingEncounter(state, action) {
      state.isLoading = action.payload;
    },

    loadEncounter(state, action) {
      state.encounters = action.payload;
    }
  }
});

export default slice.reducer;
export const { isLoadingEncounter, loadEncounter } = slice.actions;
