import { Link, ListItem, ListItemAvatar, ListItemText, Stack, Theme, Typography } from '@mui/material';
import UserAvatar from 'components/UserAvatar';
import { formatUtcToLocalDateTime } from 'utils/helpers/dateTimeHelper';
import ShowMoreTextContent from 'components/ShowMoreTextContent';
import { IActivity } from 'types';
import { useActivityItem } from './use-activity-item';

interface IActivityItemProps {
  activityItem: IActivity;
  theme: Theme;
  showSmallText: boolean;
}

export const ActivityItem = (props: IActivityItemProps) => {
  const { theme, showSmallText } = props;
  const { activityAvatarDisplayName, activityDescription, activityMessage, activityDate, taskName, handleTaskLink } = useActivityItem(
    props.activityItem
  );

  return (
    <Stack style={{ borderBottom: `1px solid ${theme.palette.secondary[200]}`, cursor: 'default' }}>
      <ListItem>
        <ListItemAvatar>
          <UserAvatar displayName={activityAvatarDisplayName} size={showSmallText ? 'md' : 'lg'} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {activityDescription}
              {` `}
              <Link sx={{ cursor: 'pointer' }} onClick={handleTaskLink}>
                #{taskName}
              </Link>
            </>
          }
          secondary={formatUtcToLocalDateTime(activityDate)}
          primaryTypographyProps={{ variant: 'subtitle1', fontSize: showSmallText ? 10 : 12, align: 'left' }}
          secondaryTypographyProps={{ variant: 'caption', fontSize: showSmallText ? 10 : 12, color: 'secondary' }}
        />
      </ListItem>
      <Typography component="span" sx={{ pl: 2, pr: 2, pb: '4px' }} fontSize={showSmallText ? 10 : 12} variant="body1">
        <ShowMoreTextContent textContent={activityMessage} showLines={2} />
      </Typography>
    </Stack>
  );
};
