import { Box, Backdrop, Fade, IconButton, Modal, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFileController } from 'packages/file-handling';
import FileViewer from './FileViewer';

export const FileViewerModal = () => {
  const { previewState, clearPreview } = useFileController();
  const modalOpen = previewState !== undefined;

  if (!modalOpen) {
    return null;
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={clearPreview}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={modalOpen}>
        <Stack sx={style}>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', borderBottom: '1px solid black' }}>
            <IconButton aria-label="close" onClick={clearPreview}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <FileViewer previewFileList={previewState.fileList} displayFile={previewState.selectedFile!} />
        </Stack>
      </Fade>
    </Modal>
  );
};
