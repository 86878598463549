import { useQuery } from '@tanstack/react-query';
import { loggedInUser, useOrgService } from 'hooks';
import { IRegion, regionEnum } from 'types';

interface RegionCache {
  regions: IRegion[];
  isRegionsLoading: boolean;
  nyRegion: IRegion | undefined;
  flRegion: IRegion | undefined;
  laRegion: IRegion | undefined;
  sfRegion: IRegion | undefined;
}

export const useRegionsCache = (): RegionCache => {
  const orgService = useOrgService();
  const { isLoggedIn } = loggedInUser.useController();

  const regionQuery = useQuery({
    queryKey: ['regions'],
    queryFn: () => orgService.getSollisRegions(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const regions = regionQuery.data ? regionQuery.data : [];
  const isRegionsLoading = regionQuery.isLoading;

  const nyRegion = regions.find((r) => r.id === regionEnum.NY);
  const flRegion = regions.find((r) => r.id === regionEnum.FL);
  const laRegion = regions.find((r) => r.id === regionEnum.LA);
  const sfRegion = regions.find((r) => r.id === regionEnum.SF);

  return {
    regions,
    isRegionsLoading,
    nyRegion,
    flRegion,
    laRegion,
    sfRegion
  };
};
