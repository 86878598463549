import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Box } from '@mui/material';
import { useSelector, dispatch, setIsUIVersionOutOfDate } from 'store';

const OutOfDateModal: React.VFC = () => {
  const { isUIVersionOutOfDate } = useSelector((state) => state.root);

  const handleConfirm = () => {
    dispatch(setIsUIVersionOutOfDate(false));
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  };

  return (
    <Dialog
      open={isUIVersionOutOfDate}
      onClose={handleConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          width: '30%',
          height: '30%'
        }
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: '1rem'
            }}
          >
            The application is out of date. Please confirm to update.
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirm()} autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutOfDateModal;
