import { useMemo, useState } from 'react';
import { findStatusTaskCounts } from './task-utilities';
import { createContextForController } from 'react-controller-context';
import dashboardContext from 'views/Dashboard/useDashboardContext';
import { ITask } from 'types';
import moment from 'moment';
import { taskPriorityEnum, taskStatusEnum } from 'types/store/taskEnums';
import { useOnUpdateEffect } from 'hooks';

export interface TaskFiltersContext {
  useSmsFilter: boolean;
  useEmailFilter: boolean;
  useReplyFilter: boolean;
  useUnassignedFilter: boolean;
  statusFilter: taskStatusEnum | null;
  priorityFilter: taskPriorityEnum | null;
  newRedrawKey: string;
  inProgressRedrawKey: string;
  smsCounts: number;
  emailCounts: number;
  replyCounts: number;
  unassignedCounts: number;
  newCounts: number[];
  hasNewTasks: boolean;
  inProgressCounts: number[];
  hasInProgressTasks: boolean;
  filteredTasks: ITask[];
  handleSmsFilter: () => void;
  handleEmailFilter: () => void;
  handleReplyFilter: () => void;
  handleUnassignedFilter: () => void;
  handlePriorityFilter: (status: taskStatusEnum, filter: taskPriorityEnum | null) => void;
}

export enum FILTER_TASK_STATUS {
  NEW = 1,
  IN_PROGRESS = 2
}

export enum FILTER_TASK_PRIORITY {
  ROUTINE = 1,
  HIGH = 2,
  EMERGENCY = 3
}

interface ITaskFilters {
  useSmsFilter: boolean;
  useEmailFilter: boolean;
  useReplyFilter: boolean;
  useUnassignedFilter: boolean;
  statusFilter: taskStatusEnum | null;
  priorityFilter: taskPriorityEnum | null;
  newRedrawKey?: string;
  inProgressRedrawKey?: string;
}

const initialFilters: ITaskFilters = {
  useSmsFilter: false,
  useEmailFilter: false,
  useReplyFilter: false,
  useUnassignedFilter: false,
  statusFilter: null,
  priorityFilter: null
};

export const useTaskFiltersController = (): TaskFiltersContext => {
  const { summaryTasks, summaryFilter } = dashboardContext.useController();
  const [taskFilters, setTaskFilters] = useState<ITaskFilters>({ ...initialFilters });

  useOnUpdateEffect(() => {
    setTaskFilters({ ...initialFilters });
  }, [summaryFilter]);

  const smsCounts = useMemo(() => summaryTasks.filter((t) => t.isSmsTask).length, [summaryTasks]);
  const emailCounts = useMemo(() => summaryTasks.filter((t) => t.isEmailTask).length, [summaryTasks]);

  const handleSmsFilter = () => {
    setTaskFilters((currentFilters) => {
      return {
        ...currentFilters,
        useSmsFilter: !currentFilters.useSmsFilter,
        priorityFilter: null,
        statusFilter: null,
        newRedrawKey: currentFilters.statusFilter === taskStatusEnum.New ? moment.utc().format('x') : currentFilters.newRedrawKey,
        inProgressRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.InProgress ? moment.utc().format('x') : currentFilters.inProgressRedrawKey
      };
    });
  };

  const handleEmailFilter = () => {
    setTaskFilters((currentFilters) => {
      return {
        ...currentFilters,
        useEmailFilter: !currentFilters.useEmailFilter,
        priorityFilter: null,
        statusFilter: null,
        newRedrawKey: currentFilters.statusFilter === taskStatusEnum.New ? moment.utc().format('x') : currentFilters.newRedrawKey,
        inProgressRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.InProgress ? moment.utc().format('x') : currentFilters.inProgressRedrawKey
      };
    });
  };

  const handleReplyFilter = () => {
    setTaskFilters((currentFilters) => {
      return {
        ...currentFilters,
        useReplyFilter: !currentFilters.useReplyFilter,
        priorityFilter: null,
        statusFilter: null,
        newRedrawKey: currentFilters.statusFilter === taskStatusEnum.New ? moment.utc().format('x') : currentFilters.newRedrawKey,
        inProgressRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.InProgress ? moment.utc().format('x') : currentFilters.inProgressRedrawKey
      };
    });
  };

  const handleUnassignedFilter = () => {
    setTaskFilters((currentFilters) => {
      return {
        ...currentFilters,
        useUnassignedFilter: !currentFilters.useUnassignedFilter,
        priorityFilter: null,
        statusFilter: null,
        newRedrawKey: currentFilters.statusFilter === taskStatusEnum.New ? moment.utc().format('x') : currentFilters.newRedrawKey,
        inProgressRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.InProgress ? moment.utc().format('x') : currentFilters.inProgressRedrawKey
      };
    });
  };

  const handlePriorityFilter = (status: taskStatusEnum, filter: taskPriorityEnum | null) => {
    setTaskFilters((currentFilters) => {
      return {
        ...currentFilters,
        priorityFilter: status === currentFilters.statusFilter && filter === currentFilters.priorityFilter ? null : filter,
        statusFilter: status === currentFilters.statusFilter && filter === currentFilters.priorityFilter ? null : status,
        newRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.New && status !== taskStatusEnum.New
            ? moment.utc().format('x')
            : currentFilters.newRedrawKey,
        inProgressRedrawKey:
          currentFilters.statusFilter === taskStatusEnum.InProgress && status !== taskStatusEnum.InProgress
            ? moment.utc().format('x')
            : currentFilters.inProgressRedrawKey
      };
    });
  };

  const replyCounts = useMemo(
    () =>
      summaryTasks.filter(
        (t) =>
          t.hasNewReply &&
          (taskFilters.useSmsFilter && taskFilters.useEmailFilter
            ? t.isSmsTask || t.isEmailTask
            : (!taskFilters.useSmsFilter || t.isSmsTask) && (!taskFilters.useEmailFilter || t.isEmailTask))
      ).length,
    [summaryTasks, taskFilters.useSmsFilter, taskFilters.useEmailFilter]
  );

  const unassignedCounts = useMemo(
    () =>
      summaryTasks.filter(
        (t) =>
          !t.assignedToUserId &&
          (taskFilters.useSmsFilter && taskFilters.useEmailFilter
            ? t.isSmsTask || t.isEmailTask
            : (!taskFilters.useSmsFilter || t.isSmsTask) && (!taskFilters.useEmailFilter || t.isEmailTask)) &&
          (!taskFilters.useReplyFilter || t.hasNewReply)
      ).length,
    [summaryTasks, taskFilters.useSmsFilter, taskFilters.useEmailFilter, taskFilters.useReplyFilter]
  );

  const statusTasks = useMemo(
    () =>
      summaryTasks
        .filter((t) =>
          taskFilters.useSmsFilter && taskFilters.useEmailFilter
            ? t.isSmsTask || t.isEmailTask
            : (!taskFilters.useSmsFilter || t.isSmsTask) && (!taskFilters.useEmailFilter || t.isEmailTask)
        )
        .filter((t) => !taskFilters.useReplyFilter || t.hasNewReply)
        .filter((t) => !taskFilters.useUnassignedFilter || !t.assignedToUserId),
    [summaryTasks, taskFilters.useSmsFilter, taskFilters.useEmailFilter, taskFilters.useReplyFilter, taskFilters.useUnassignedFilter]
  );

  const newCounts = useMemo(
    () =>
      findStatusTaskCounts(
        statusTasks,
        taskStatusEnum.New,
        taskFilters.useSmsFilter,
        taskFilters.useEmailFilter,
        taskFilters.useReplyFilter,
        taskFilters.useUnassignedFilter
      ),
    [summaryTasks, taskFilters.useSmsFilter, taskFilters.useEmailFilter, taskFilters.useReplyFilter, taskFilters.useUnassignedFilter]
  );
  const hasNewTasks = Boolean(newCounts.find((c) => c > 0));

  const inProgressCounts = useMemo(
    () =>
      findStatusTaskCounts(
        statusTasks,
        taskStatusEnum.InProgress,
        taskFilters.useSmsFilter,
        taskFilters.useEmailFilter,
        taskFilters.useReplyFilter,
        taskFilters.useUnassignedFilter
      ),
    [summaryTasks, taskFilters.useSmsFilter, taskFilters.useEmailFilter, taskFilters.useReplyFilter, taskFilters.useUnassignedFilter]
  );
  const hasInProgressTasks = Boolean(inProgressCounts.find((c) => c > 0));

  const filteredTasks = useMemo(
    () =>
      summaryTasks
        .filter((t) =>
          taskFilters.useSmsFilter && taskFilters.useEmailFilter
            ? t.isSmsTask || t.isEmailTask
            : (!taskFilters.useSmsFilter || t.isSmsTask) && (!taskFilters.useEmailFilter || t.isEmailTask)
        )
        .filter((t) => !taskFilters.useReplyFilter || t.hasNewReply)
        .filter((t) => !taskFilters.useUnassignedFilter || !t.assignedToUserId)
        .filter((t) => !taskFilters.statusFilter || t.taskStatusId === taskFilters.statusFilter)
        .filter((t) => !taskFilters.priorityFilter || t.taskPriorityId === taskFilters.priorityFilter),
    [
      summaryTasks,
      taskFilters.useSmsFilter,
      taskFilters.useEmailFilter,
      taskFilters.useReplyFilter,
      taskFilters.useUnassignedFilter,
      taskFilters.statusFilter,
      taskFilters.priorityFilter
    ]
  );

  return {
    filteredTasks,
    useSmsFilter: taskFilters.useSmsFilter,
    useEmailFilter: taskFilters.useEmailFilter,
    useReplyFilter: taskFilters.useReplyFilter,
    useUnassignedFilter: taskFilters.useUnassignedFilter,
    statusFilter: taskFilters.statusFilter,
    priorityFilter: taskFilters.priorityFilter,
    newRedrawKey: taskFilters.newRedrawKey ?? '',
    inProgressRedrawKey: taskFilters.inProgressRedrawKey ?? '',
    smsCounts,
    emailCounts,
    replyCounts,
    unassignedCounts,
    newCounts,
    hasNewTasks,
    inProgressCounts,
    hasInProgressTasks,
    handleSmsFilter,
    handleEmailFilter,
    handleReplyFilter,
    handleUnassignedFilter,
    handlePriorityFilter
  };
};

const taskFiltersContext = createContextForController(useTaskFiltersController);

export default taskFiltersContext;
