export abstract class ApiService {
  abstract get(resourcePath: string): Promise<any>;
  abstract delete(resourcePath: string): Promise<any>;
  abstract post(resourcePath: string, payload: any): Promise<any>;
  abstract put(resourcePath: string, payload?: any): Promise<any>;

  //  Builds a URL query string from a key and value ex: encodeQueryParam('foo', 'test') === 'foo=test'
  encodeQueryParam(key: string, value: any): string {
    if (Array.isArray(value)) {
      return value.map((v) => this.encodeQueryParam(key, v)).join('&');
    }

    if (typeof value === 'object') {
      //  TODO: support object values later on somehow
      return '';
    }

    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    return `${encodedKey}=${encodedValue}`;
  }
}

export default ApiService;
