import { MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { IRequestConfig } from 'types';
import { api } from 'utils';
import settings from 'constants/appsettings.json';

export interface LocationSelectorProps {
  onSelect: (event: any) => void;
  currentLocation: string | '' | undefined;
  label: string;
  name: string;
  helperText: string | false | undefined;
  error: boolean | undefined;
  isDisabled: boolean | undefined;
  isRequired: boolean | undefined;
}

const LocationSelector: React.VFC<LocationSelectorProps> = (props) => {
  const { onSelect, currentLocation, label, name, helperText, error, isDisabled, isRequired } = props;
  const [locationOptions, setLocationOptions] = useState<any[] | []>([]);

  useEffect(() => {
    if (locationOptions.length === 0) {
      const onResolve = async (res: any) => {
        const data = await res.data;
        setLocationOptions(data);
      };

      const url = `${settings.apiDetails.baseUri}appointments/v1/AppointmentComponents/locations`;
      const config: IRequestConfig = {
        method: 'get',
        url: url,
        onResolve
      };
      api.processRequest(config);
    }
  }, [locationOptions]);

  return (
    <TextField
      select
      required={isRequired}
      disabled={isDisabled}
      label={label}
      id={name}
      name={name}
      onChange={onSelect}
      value={currentLocation}
      helperText={helperText}
      error={error}
    >
      {locationOptions.map((item, index: number) => {
        return (
          <MenuItem key={index} value={item.locationName}>
            {`${item.locationName} - ${item.locationState}`}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default LocationSelector;
