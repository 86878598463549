import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface ConfirmationModalProps {
  title?: string;
  message?: string;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

const ConfirmationModal: React.VFC<ConfirmationModalProps> = (props) => {
  const { title, message, confirmButtonTitle = 'Yes', cancelButtonTitle = 'No', onConfirm, onCancel, open } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title ?? 'Confirmation'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message ?? 'Are you sure you want to continue?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()}>{cancelButtonTitle}</Button>
        <Button onClick={() => handleConfirm()} autoFocus>
          {confirmButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
