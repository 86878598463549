import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { dispatch, resetMemberState } from 'store';
import { createContextForController } from 'react-controller-context';
import { ILinkedTask, IMember } from 'types';

export interface GlobalSearchContext {
  searchType: string;
  currentMemberId?: number;
  currentTaskId?: string;
  setSearchType: (s: string) => void;
  handleMemberSelect: (event: any) => void;
  handleTaskSelect: (event: any) => void;
}

export const useGlobalSearch = (): GlobalSearchContext => {
  const location = useLocation();
  const [searchType, setSearchType] = useState<string>('member');
  const navigate = useNavigate();

  const memberId = location?.state?.paramsData?.memberId;
  const taskId = location?.state?.paramsData?.taskId;

  const handleMemberSelect = (event: any) => {
    const selectedMember = event.target.value as IMember;
    if (selectedMember.memberId !== memberId) {
      dispatch(resetMemberState());
      navigate('/member', {
        state: {
          paramsData: {
            memberId: selectedMember.memberId
          }
        }
      });
    }
  };

  const handleTaskSelect = (event: any) => {
    const selectedTask = event.target.value as ILinkedTask;
    if (selectedTask.taskId !== taskId) {
      dispatch(resetMemberState());
      navigate(`/task`, {
        state: {
          paramsData: {
            taskId: selectedTask.taskId
          }
        }
      });
    }
  };

  return {
    searchType,
    currentMemberId: memberId,
    currentTaskId: taskId,
    setSearchType,
    handleMemberSelect,
    handleTaskSelect
  };
};

const globalSearchContext = createContextForController(useGlobalSearch);

export default globalSearchContext;
