import MaterialReactTable from 'material-react-table';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import AssignOwnerModal from './components/AssignOwnerModal';
import useTaskGrid from './use-task-grid';
import { loggedInUser } from 'hooks';

const TaskGrid: React.VFC = () => {
  const { hasTeams } = loggedInUser.useController();
  const { navigateMember, columns, filteredTasks, selectedTask, setSelectedTask } = useTaskGrid();

  if (!hasTeams) {
    return null;
  }

  return (
    <MainCard content={false}>
      <ScrollX>
        <MaterialReactTable
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              cursor: 'pointer'
            }
          })}
          muiTableBodyCellProps={({ cell, row }) => ({
            onClick: (event) => {
              if (cell?.column?.id !== 'assignedToUserName' && cell?.column?.id !== 'taskDescription') {
                navigateMember(row.original);
              }
            }
          })}
          columns={columns}
          data={filteredTasks}
          initialState={{
            columnVisibility: {
              vipMember: false
            }
          }}
        />
      </ScrollX>
      <AssignOwnerModal task={selectedTask} open={selectedTask != null} onClose={() => setSelectedTask(null)} />
    </MainCard>
  );
};

export default TaskGrid;
