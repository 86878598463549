import { FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';
import { useSearchFilters } from './search-filters-provider';
import { SearchFilterField } from '../definitions';
import React from 'react';

interface Props {
    label: string;
    field: SearchFilterField;
    wrapper?: React.FC<React.PropsWithChildren>;
}

export const ToggleInput = ({
    label,
    field,
    wrapper: Wrapper = ({ children }: React.PropsWithChildren) => (<>{children}</>)
}: Props) => {
    const { getValue, setValue } = useSearchFilters();
    return (
        <Wrapper >
            <FormLabel>{label}</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox
                        checked={Boolean(getValue(field)) || false}
                        onChange={e => setValue(field, e.target.checked)}
                    />}
                    label={label}
                />
            </FormGroup>
        </Wrapper>
    );
};