import { FC, ReactNode } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'contexts/ConfigContext';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { store, persister, setNavigationState, dispatch } from 'store';
import { msalConfig } from 'utils';

export const msalInstance = new PublicClientApplication(msalConfig);

if (msalInstance && typeof msalInstance.handleRedirectPromise === 'function') {
  const result = msalInstance.handleRedirectPromise();

  if (result && typeof result.then === 'function') {
    result.then((tokenResponse) => {
      if (tokenResponse === null) {
        return;
      }

      if (tokenResponse.state && tokenResponse.state !== '') {
        const stateObject = JSON.parse(tokenResponse.state);
        dispatch(setNavigationState(stateObject));
      }
    });
  }
}

interface ProviderProps {
  children: ReactNode;
}

const Provider: FC<ProviderProps> = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <ConfigProvider>
            <BrowserRouter>{children}</BrowserRouter>
          </ConfigProvider>
        </PersistGate>
      </ReduxProvider>
    </MsalProvider>
  );
};

export default Provider;
