import { useQuery } from '@tanstack/react-query';
import { loggedInUser, useOrgService } from 'hooks';
import { ITeam } from 'types';

interface TeamsCache {
  teams: ITeam[];
  isTeamsLoading: boolean;
}

export const useTeamsCache = (): TeamsCache => {
  const orgService = useOrgService();
  const { isLoggedIn } = loggedInUser.useController();

  const teamsQuery = useQuery({
    queryKey: ['teams'],
    queryFn: () => orgService.getGroups(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const teams = teamsQuery.data ? teamsQuery.data : [];
  const isTeamsLoading = teamsQuery.isPending || teamsQuery.isLoading;

  return {
    teams,
    isTeamsLoading
  };
};
