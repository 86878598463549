import { ITaskAssignedTo, ITaskParticipant } from 'types';
import ApiService from './api-service';

export class UserService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  buildSearchString(searchString: string = ''): string {
    let queryString = '';
    let searchFirstName = '';
    let searchLastName = '';

    if (searchString.indexOf(',') !== -1) {
      const nameSeparatorIndex = searchString.indexOf(',');
      searchFirstName = searchString.slice(nameSeparatorIndex + 1).trim();
      searchLastName = searchString.slice(0, nameSeparatorIndex).trim();
    } else {
      const nameParts = searchString.split(' ');
      searchFirstName = nameParts.length > 1 ? nameParts[0] : '';
      searchLastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
    }

    if (searchFirstName && searchLastName) {
      queryString = `searchFirstName=${searchFirstName}&searchLastName=${searchLastName}`;
    } else {
      queryString = `searchString=${searchString.trim()}`;
    }

    return queryString;
  }

  findOwners(searchString: string = ''): Promise<ITaskAssignedTo[]> {
    let queryString = this.buildSearchString(searchString);

    return this.apiService.get(`/v1/SearchOwners?${queryString}`).then((r) => r?.data);
  }

  findUsers(searchString: string = ''): Promise<ITaskParticipant[]> {
    let queryString = this.buildSearchString(searchString);

    return this.apiService.get(`/v1/FindUsers?${queryString}`).then((r) => r?.data);
  }
}

export default UserService;
