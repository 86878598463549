import { ActivityCache, activitySignalr, loggedInUser, useActivityCache, useOnUpdateEffect } from 'hooks';
import { createContextForController } from 'react-controller-context';
import { useCallback, useState } from 'react';
import { FilterDate } from 'components';
import { useDebouncedState } from 'sollishealth.core.react';
import { Moment } from 'moment';
import dashboardContext from 'views/Dashboard/useDashboardContext';

interface ActivityFiltersContext {
  searchString: string;
  filterDate: Moment | null;
  filterTypes: string[];
  filterTeams: string[];
  hasFilters: boolean;
  handleSearchString: (s: string) => void;
  handleFilterDate: (date: FilterDate | null) => void;
  handleFilterTypes: (t: string[]) => void;
  handleFilterTeams: (t: string[]) => void;
  resetFilters: () => void;
}

interface RecentActivityContext extends ActivityFiltersContext {
  showLoading: boolean;
  showEmptyResults: boolean;
  activities: ActivityCache['activities'];
  hasActivities: ActivityCache['hasActivities'];
  hasNextPage: ActivityCache['hasNextPage'];
  fetchNextPage: ActivityCache['fetchNextPage'];
  showFilters: boolean;
  setShowFilters: (f: boolean) => void;
}

export const useRecentActivityController = (): RecentActivityContext => {
  const { newActivity } = activitySignalr.useController();
  const { isMyTeamsLoading } = loggedInUser.useController();
  const { isTasksLoading } = dashboardContext.useController();
  const {
    isActivityLoading,
    activities,
    hasActivities,
    isSearchLoading,
    hasSearchResults,
    hasNextPage,
    fetchNextPage,
    setActivityFilters,
    addToActivityCache
  } = useActivityCache();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<FilterDate | null>(null);
  const [debouncedSearchString, setSearchString, searchString] = useDebouncedState('');
  const [debouncedFilterTypes, setFilterTypes, filterTypes] = useDebouncedState([] as string[]);
  const [debouncedFilterTeams, setFilterTeams, filterTeams] = useDebouncedState([] as string[]);

  useOnUpdateEffect(() => {
    if (newActivity) {
      addToActivityCache(newActivity);
    }
  }, [newActivity]);

  const handleSearchString = useCallback(
    (search: string) => {
      setSearchString(search);

      if (!Boolean(search.length) && !Boolean(filterTypes.length) && !Boolean(filterTeams.length) && !Boolean(filterDate)) {
        setActivityFilters(null);
      }
    },
    [filterTypes, filterTeams, filterDate]
  );

  const handleFilterTypes = useCallback(
    (types: string[]) => {
      setFilterTypes(types);

      if (!Boolean(types.length) && !Boolean(searchString.length) && !Boolean(filterTeams.length) && !Boolean(filterDate)) {
        setActivityFilters(null);
      }
    },
    [searchString, filterTeams, filterDate]
  );

  const handleFilterTeams = useCallback(
    (teams: string[]) => {
      setFilterTeams(teams);

      if (!Boolean(teams.length) && !Boolean(searchString.length) && !Boolean(filterTypes.length) && !Boolean(filterDate)) {
        setActivityFilters(null);
      }
    },
    [searchString, filterTypes, filterDate]
  );

  const handleFilterDate = useCallback(
    (date: FilterDate | null) => {
      setFilterDate(date);

      if (!Boolean(date) && !Boolean(searchString.length) && !Boolean(filterTypes.length) && !Boolean(filterTeams.length)) {
        setActivityFilters(null);
      }
    },
    [searchString, filterTypes, filterTeams]
  );

  const resetFilters = () => {
    setActivityFilters(null);
    setSearchString('');
    setFilterTypes([]);
    setFilterTeams([]);
    setFilterDate(null);
  };

  useOnUpdateEffect(() => {
    if (!Boolean(searchString.length) && !Boolean(filterTypes.length) && !Boolean(filterTeams.length) && !Boolean(filterDate)) {
      return;
    }

    const filters = {
      searchString: debouncedSearchString,
      typeFilters: debouncedFilterTypes,
      activityTeamIds: debouncedFilterTeams,
      filterStartDate: filterDate?.dateRange[0],
      filterEndDate: filterDate?.dateRange[1]
    };
    setActivityFilters(filters);
  }, [debouncedSearchString, debouncedFilterTypes, debouncedFilterTeams, filterDate]);

  const canSearch =
    (Boolean(debouncedSearchString.length) && debouncedSearchString.length > 2) ||
    Boolean(debouncedFilterTypes.length) ||
    Boolean(debouncedFilterTeams.length) ||
    Boolean(filterDate);

  // If my teams is loading, we dont want to show 2 loaders on the screen
  // So only show activity loader, if needed, after my teams has loaded
  const hasFilters = Boolean(searchString.length) || Boolean(filterTypes.length) || Boolean(filterTeams.length) || Boolean(filterDate);
  const showLoading = !isMyTeamsLoading && !isTasksLoading && (isActivityLoading || isSearchLoading || (hasFilters && !canSearch));

  return {
    showLoading,
    showEmptyResults:
      !isMyTeamsLoading && !isTasksLoading && !showLoading && (!hasActivities || (hasFilters && canSearch && !hasSearchResults)),
    activities,
    hasActivities,
    hasNextPage,
    fetchNextPage,
    showFilters,
    setShowFilters,
    searchString,
    filterDate: filterDate?.date ?? null,
    filterTeams,
    filterTypes,
    hasFilters,
    handleSearchString,
    handleFilterDate,
    handleFilterTeams,
    handleFilterTypes,
    resetFilters
  };
};

const activityContext = createContextForController(useRecentActivityController);

export default activityContext;
