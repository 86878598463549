import { createContextForController } from 'react-controller-context';
import React from 'react';
import { useSearchFilters } from './search-filters-provider';

enum Modes {
    SEARCH,
    BROWSE
}

const useController = () => {
    const { hasFilters } = useSearchFilters();
    const [ mode, setMode ] = React.useState<Modes>(Modes.SEARCH);

    const isFormulatingSearch = mode === Modes.SEARCH;
    const isBrowsingResults = mode === Modes.BROWSE;

    const browseResults = () => {
        if (hasFilters) {
            setMode(Modes.BROWSE);
        }
    };

    const formulateSearch = () => {
        setMode(Modes.SEARCH);
    };

    const canBrowse = hasFilters;

    return {
        isFormulatingSearch,
        isBrowsingResults,
        browseResults,
        formulateSearch,
        canBrowse
    };
};

const context = createContextForController(useController);
export const SearchModeProvider = context.Provider;
export const useSearchMode = context.useController;
