import { createRoot } from 'react-dom/client';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';
import 'assets/App.css';
import 'sollishealth.core.react/dist/index.css';
import { datadogRum } from '@datadog/browser-rum';
import appSettings from './constants/appsettings.json';
import App from 'views/App';
import Provider from 'contexts/Provider';
import reportWebVitals from './reportWebVitals';
import Package from '../package.json';
import { datadogLogs } from '@datadog/browser-logs';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider>
    <App />
  </Provider>
);

reportWebVitals();

datadogLogs.init({
  clientToken: 'pub877c4579ace4ad666be11ecd8fd4da8e',
  site: 'us3.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'navigator',
  version: Package.version,
  env: appSettings.environment,
  telemetrySampleRate: 100
});

datadogRum.init({
  applicationId: 'd7b16c52-4d48-4405-bb65-e5e75edcf7ff',
  clientToken: 'pub877c4579ace4ad666be11ecd8fd4da8e',
  site: 'us3.datadoghq.com',
  service: 'navigator',
  version: Package.version,
  env: appSettings.environment,
  allowedTracingUrls: [(url) => url.startsWith(appSettings.apiDetails.baseUri)],
  traceSampleRate: appSettings.datadog.traceSampleRate,
  // Specify a version number to identify th.e deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: appSettings.datadog.sessionSampleRate,
  sessionReplaySampleRate: appSettings.datadog.sessionReplaySampleRate,
  telemetrySampleRate: appSettings.datadog.telemetrySampleRate,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();
