import { loggedInUser } from 'hooks';
import activityContext from '../../use-recent-activity';
import { FilterOptionsSelect } from 'components';
import { useMemo } from 'react';
import _ from 'lodash';

export const ActivityTeamFilter = () => {
  const { filterTeams, handleFilterTeams } = activityContext.useController();
  const { myTeams } = loggedInUser.useController();
  const filterTeamOptions = useMemo(
    () =>
      _.orderBy(
        myTeams.map((t) => {
          return { id: t.id, displayName: t.displayName };
        })
      ),
    [myTeams]
  );

  return (
    <FilterOptionsSelect
      allowMultiples={true}
      currentChoices={filterTeams}
      filterName="Filter Teams"
      filterOptions={filterTeamOptions}
      onChange={handleFilterTeams}
    />
  );
};
