import { useTaskLookupsCache } from 'hooks';
import { calculateSLAStatus } from 'packages/sla';
import { createContextForController } from 'react-controller-context';
import { useSelector } from 'store';
import { IAdditionalFieldValue, ITask } from 'types';
import { taskStatusEnum } from 'types/store/taskEnums';

export const useTaskController = () => {
  const { currentMemberTask } = useSelector((state) => state.member);
  const { isCacheLoaded, isCacheLoading } = useTaskLookupsCache();

  let currentTask: ITask | null = currentMemberTask;

  if (currentTask) {
    let slaStatus = calculateSLAStatus(currentMemberTask);

    let additionalFieldData = {};
    currentMemberTask?.additionalFieldValues?.forEach((f: IAdditionalFieldValue) => {
      if (f.fieldValue) {
        Object.assign(additionalFieldData, {
          [f.fieldName]: f.fieldValue
        });
      }
    });

    currentTask = {
      ...currentMemberTask,
      slaStatus,
      additionalFieldData: additionalFieldData
    } as ITask;
  }

  return {
    currentMemberTask: currentTask,
    isTaskClosed: currentMemberTask?.taskStatusId === taskStatusEnum.Closed,
    isLookupsLoading: isCacheLoading,
    hasLookupsLoaded: isCacheLoaded
  };
};

const taskContext = createContextForController(useTaskController);

export default taskContext;
