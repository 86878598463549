import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { SearchFilterField, ISelectorOption } from '../definitions';

const convertListToOptions = (list: string[] = []) => list.map(i => ({ label: i, value: i}));

export const useMedPartnerEnumerationSetter = (
    field: SearchFilterField,
    service: () => Promise<string[]>,
    optionSetter: (k: SearchFilterField, d: ISelectorOption[]) => void
) => {
    const citiesQuery = useQuery({
        queryKey: ['medPartnerEnums', field],
        queryFn: () => service()
    });
    React.useEffect(
        () => optionSetter(field, convertListToOptions(citiesQuery.data)),
        [citiesQuery.data]
    );
}