import { TextField, FormLabel } from '@mui/material';
import { useSearchFilters } from './search-filters-provider';
import { SearchFilterField } from '../definitions';
import React from 'react';

interface Props {
    label: string;
    field: SearchFilterField;
    wrapper?: React.FC<React.PropsWithChildren>;
}

export const TextInput = ({
    label,
    field,
    wrapper: Wrapper = ({ children }: React.PropsWithChildren) => (<>{children}</>)
}: Props) => {
    const { getValue, setValue } = useSearchFilters();
    return (
        <Wrapper >
            <FormLabel>{label}</FormLabel>
            <TextField
                value={getValue(field) || ''}
                onChange={e => setValue(field, e.target.value)}
                autoComplete='off'
                fullWidth
            />
        </Wrapper>
    );
};