import { api } from 'utils';
import { Encounter, IRequestConfig } from 'types';
import { dispatch } from 'store';
import { loadEncounter, isLoadingEncounter } from './encounter.slice';
import settings from 'constants/appsettings.json';

export function getEncounter(id: number | undefined, showLoading: boolean | undefined = false) {
  if (showLoading) {
    dispatch(isLoadingEncounter(true));
  }
  const onResolve = async (res: any) => {
    const data: any = res.data;

    const sortedData = data.sort((a: Encounter, b: Encounter) => {
      const aDate = new Date(a.startDateTime);
      const bDate = new Date(b.startDateTime);
      return bDate.getTime() - aDate.getTime();
    });

    dispatch(loadEncounter(sortedData));
    dispatch(isLoadingEncounter(false));
  };

  const onReject = (err: unknown) => {
    dispatch(isLoadingEncounter(false));
  };

  const url = `${settings.apiDetails.baseUri}member/v1/getEncountersForMember?memberId=${id}`;

  const config: IRequestConfig = {
    method: 'get',
    url: url,
    onResolve,
    onReject
  };
  api.processRequest(config);
}
