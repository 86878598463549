import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { GuardProps } from 'types';
import { login } from 'services/Auth/Auth.service';

const AutoLoginAuthGuard = ({ children }: GuardProps) => {
  const { instance } = useMsal();
  const isLoggedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const paramsData = useParams();

  useEffect(() => {
    if (!isLoggedIn) {
      if (paramsData && ((paramsData.memberId && paramsData.createMode) || paramsData.taskId)) {
        login(instance, paramsData);
      } else {
        navigate('/login', {
          replace: true
        });
      }
    } else {
      if (paramsData && !paramsData.memberId && paramsData.taskId) {
        navigate('/task', {
          replace: true,
          state: {
            paramsData: paramsData
          }
        });
      }
      if (paramsData && ((paramsData.memberId && paramsData.createMode) || paramsData.taskId)) {
        navigate('/member', {
          replace: true,
          state: {
            paramsData: paramsData
          }
        });
      }
    }
  }, [isLoggedIn, paramsData, navigate]);

  if (!isLoggedIn || !paramsData) {
    return <></>;
  }
  return <>{children}</>;
};

export default AutoLoginAuthGuard;
