import { loggedInUser, useConversationService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useConversationTypesCache = () => {
  const conversationService = useConversationService();
  const { isLoggedIn } = loggedInUser.useController();

  const conversationTypeQuery = useQuery({
    queryKey: ['conversationTypes'],
    queryFn: () => conversationService.getConversationTypes(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isConversationTypesLoading, data: conversationTypes = [] } = conversationTypeQuery;

  return {
    isConversationTypesLoading,
    conversationTypes
  };
};
