import activityContext from '../use-recent-activity';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingMoreIndicator } from '../loading-more-indicator';
import { ActivityList } from 'components';

export const RecentActivityList = () => {
  const { activities, fetchNextPage, hasNextPage } = activityContext.useController();

  return (
    <InfiniteScroll
      dataLength={activities.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<LoadingMoreIndicator />}
      scrollableTarget="activityScroller"
    >
      <ActivityList activities={activities} />
    </InfiniteScroll>
  );
};
