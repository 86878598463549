import { Stack } from '@mui/material';
import PreviewFileList from './PreviewFileList';
import PreviewFile from './PreviewFile';
import FileViewerFooter from './FileViewerFooter';
import PreviewProvider, { PreviewProviderProps } from './PreviewProvider';
import { PreviewControllerProps } from './use-preview-controller';

const FileViewer: React.VFC<Omit<PreviewProviderProps, 'children'>> = (props: PreviewControllerProps) => {
  return (
    <PreviewProvider {...props}>
      <Stack>
        <Stack direction={'row'}>
          <PreviewFileList />
          <PreviewFile />
        </Stack>
        <FileViewerFooter />
      </Stack>
    </PreviewProvider>
  );
};

export default FileViewer;
