import queryString from 'query-string';
import { set } from 'lodash';

export class QueryStringBuilder {
  private params: object = {};

  public hasParams(): boolean {
    return Boolean(Object.keys(this.params).length);
  }

  /**
   * output the final query string
   */
  public get queryString(): string {
    return queryString.stringify(this.params);
  }

  /**
   * Adds a key/value pair to the queryString
   * @param key - Key for param
   * @param value - Value for param
   */
  public addParam(key: string, value: any) {
    set(this.params, key, value);
  }

  //  TODO: implement addArrayParam
}
