import { ILabResult } from 'types';
import ApiService from './api-service';

export type LabResultId = ILabResult['labResultId'];
export type LabResultFileName = string;
export type LabResultFileNames = LabResultFileName[];
export type LabResultFile = string;

export class LabsService {
  protected apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  getLabResultFiles(labResultId: LabResultId): Promise<LabResultFileNames> {
    return this.apiService.get(`labs/v1/files/${labResultId}`).then((r) => r?.data);
  }

  getLabResultFile(labResultId: LabResultId, labResultFileName: LabResultFileName): Promise<LabResultFile> {
    return this.apiService.get(`labs/v1/files/${labResultId}/${labResultFileName}`).then((r) => r?.data);
  }

  isConfidential(labResult: ILabResult): boolean {
    return labResult.confidential !== 'N';
  }
}

export default LabsService;
