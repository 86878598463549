import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import activityContext from './use-recent-activity';

export const NoActivityIndicator = () => {
  const { showEmptyResults, hasFilters } = activityContext.useController();

  if (!showEmptyResults) {
    return null;
  }

  let emptyStateMessage = 'You have no recent activity';
  if (hasFilters) {
    emptyStateMessage = 'No recent activity matches the filter criteria';
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', fontStyle: 'italic', pt: 2 }}>
      <Typography variant="h5">{emptyStateMessage}</Typography>
    </Box>
  );
};
