import settings from 'constants/appsettings.json';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { msalInstance } from 'contexts/Provider';
import { loggedInUser } from 'hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getAuthToken } from 'services';
import { IActivity } from 'types';
import { createContextForController } from 'react-controller-context';

const useActivitySignalr = () => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [latestItem, setLatestItem] = useState<IActivity | null>(null);
  const { userId, userGroups, userAccount } = loggedInUser.useController();

  useEffect(() => {
    createConnection();
  }, []);

  const createConnection = () => {
    if (userAccount) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(settings.apiDetails.signalRUri, {
          accessTokenFactory: async () => await getAuthToken(msalInstance, userAccount, settings.serviceScopes.ActivityService)
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((_result: any) => {
          connection.send('Echo');

          userGroups.forEach((val) => {
            connection.invoke('JoinGroup', val);
          });

          connection.on('Activity', (itemsJson: string) => {
            let item: any = JSON.parse(itemsJson);
            item = camelCaseKeys(item);
            let activity: IActivity = item as IActivity;

            if (activity.activityInitiatorId === userId) {
              return;
            }

            // UserId or TeamId will always be set on Activity
            if (activity?.userId !== userId && !userGroups.find((g) => g === activity?.teamId)) {
              return;
            }

            setLatestItem(activity);
          });
        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  const camelCaseKeys: any = (obj: any) => {
    if (!_.isObject(obj)) {
      return obj;
    } else if (_.isArray(obj)) {
      return obj.map((v) => camelCaseKeys(v));
    }
    return _.reduce(
      obj,
      (r, v, k) => {
        return {
          ...r,
          [_.camelCase(k)]: camelCaseKeys(v)
        };
      },
      {}
    );
  };

  return {
    newActivity: latestItem
  };
};

export const activitySignalr = createContextForController(useActivitySignalr);
