import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IMedicalPartner } from 'types';
import React from 'react';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

interface DataRowProps extends React.PropsWithChildren {
    label: string;
    visible?: boolean;
}

const DataRow = ({
    label,
    children,
    visible = true
}: DataRowProps) => {
    return !visible || !children ? null : (
        <TableRow>
            <TableCell align='right' colSpan={1}>
                {label}
            </TableCell>
            <TableCell align='left' colSpan={8}>{children}</TableCell>
        </TableRow>
    );
};

interface Props {
    record: IMedicalPartner;
    visible: boolean;
}

export const PartnerListDetails = ({
    record,
    visible
}: Props) => {
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: 'rgba(0,0,0,0.1)' }} colSpan={12}>
                <Collapse in={visible} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Typography variant="h4" gutterBottom component="div">
                        Medical Partner Info
                    </Typography>
                    <Typography variant="h4" gutterBottom component="div">
                        {record.fullName}
                    </Typography>
                    <Table size="small" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableBody>
                            <DataRow label='Title'>{record.title}</DataRow>
                            <DataRow label='Mobile'>{record.mobilePhoneNumber}</DataRow>
                            <DataRow label='Account Name'>{record.accountName}</DataRow>
                            <DataRow label='Office'>{record.primaryPhoneNumber}</DataRow>
                            <DataRow label='Contact Record Type'>{record.type}</DataRow>
                            <DataRow label='' visible={record.privateDoctor}>Private Doctor</DataRow>
                            <DataRow label='Primary Specialty'>{record.primarySpecialty}</DataRow>
                            <DataRow label='Other Specialty'>{record.otherSpecialty}</DataRow>
                            <DataRow label='Insurance'>{record.insuranceStatus}</DataRow>
                            <DataRow label=''>{record.insuranceDetails}</DataRow>
                            <DataRow label='' visible={record.hamptonsCoverage}>Hamptons Coverage</DataRow>
                            <DataRow label='Location'>{record.medPartnerLocation}</DataRow>
                            <DataRow label='Street'>{record.street}</DataRow>
                            <DataRow label='City'>{record.city}</DataRow>
                            <DataRow label='State'>{record.state}</DataRow>
                            <DataRow label='Country'>{record.country}</DataRow>
                            <DataRow label='Hospital Affiliation'>{record.hospitalAffiliation}</DataRow>
                            <DataRow label='Hospital Affiliation Other'>{record.hospitalAffiliationOther}</DataRow>
                        </TableBody>
                    </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};