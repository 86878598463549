import { List, useTheme } from '@mui/material';
import { ActivityCache, useCurrentWidth } from 'hooks';
import { ActivityItem } from './ActivityItem';
import { useEffect, useState } from 'react';

const useActivityList = () => {
  const browserWidth = useCurrentWidth();
  const theme = useTheme();
  const [showSmallText, setShowSmallText] = useState(browserWidth <= theme.breakpoints.values.md);

  useEffect(() => {
    setShowSmallText(browserWidth <= theme.breakpoints.values.lg && browserWidth >= theme.breakpoints.values.md);
  }, [browserWidth, theme.breakpoints.values]);

  return {
    showSmallText,
    theme
  };
};

interface IActivityListProps {
  activities: ActivityCache['activities'];
}

export const ActivityList = (props: IActivityListProps) => {
  const { activities } = props;
  const { theme, showSmallText } = useActivityList();

  if (!Boolean(activities.length)) {
    return null;
  }

  return (
    <List sx={{ width: '100%', p: 0, '& .MuiListItem-root': { py: '4px' } }}>
      {activities.map((item, index: number) => {
        return <ActivityItem key={index} activityItem={item} theme={theme} showSmallText={showSmallText} />;
      })}
    </List>
  );
};
