import { Box } from '@mui/material';
import { VolumeMute, VolumeOff } from '@mui/icons-material';
import IconButton from 'components/@extended/IconButton';
import { useSoundEffectsController } from '../../../../packages/sound-effects';

export const MuteButton = () => {
  const { toggleMute, isMuted } = useSoundEffectsController();
  const VolumeIcon = isMuted ? VolumeOff : VolumeMute;
  const tooltip = isMuted ? 'Unmute' : 'mute';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton color="secondary" size="small" variant="light" onClick={() => toggleMute()} tooltip={tooltip} title={tooltip}>
        <VolumeIcon />
      </IconButton>
    </Box>
  );
};

export default MuteButton;
