import { Chip } from '@mui/material';
import { SearchAndShow } from '../components/SearchAndShow';
import { SearchOption } from '../definitions';
import { SearchTasksProps, useSearchTasks } from './use-search-tasks';
import { SearchStringProvider } from '../search-string-provider';

const Wrapper = (props: SearchTasksProps) => {
  const { id, placeHolder, allowMultiple, isDisabled, value, defaultOptions, searchOptions, onSelect, showLoading } = useSearchTasks(props);

  return (
    <SearchAndShow
      id={id}
      value={value}
      defaultOptions={defaultOptions}
      searchOptions={searchOptions}
      placeHolder={placeHolder}
      allowMultiple={allowMultiple}
      isDisabled={isDisabled}
      showLoading={showLoading}
      onSelect={onSelect}
      renderTags={(values, getTagProps) =>
        !allowMultiple
          ? undefined
          : values?.map((value: SearchOption, index: number) => (
              <Chip
                sx={{ cursor: 'pointer' }}
                label={value.displayName}
                component="a"
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (props.handleTaskClicked) {
                    props.handleTaskClicked(value.optionObject);
                  }
                }}
                {...getTagProps({ index })}
              />
            ))
      }
    />
  );
};

export const SearchTasks = (props: SearchTasksProps) => {
  return (
    <SearchStringProvider>
      <Wrapper {...props} />
    </SearchStringProvider>
  );
};
