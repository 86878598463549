import { LabResultFileName } from 'services';
import { ILabResult } from 'types';

/*
    Standardizes the query keys (cache keys)!  Objective is to provide functions that build query keys based on params.
*/

export const useQueryKeys = () => {
  return {
    labDetails: (labResultId: ILabResult['labResultId']) => ['labDetails', labResultId],
    labDetailsFiles: (labResultId: ILabResult['labResultId']) => ['labDetails', labResultId, 'files'],
    labDetailsFile: (labResultId: ILabResult['labResultId'], fileName: LabResultFileName) => ['labDetails', labResultId, 'files', fileName]
  };
};
