import moment, { Moment } from 'moment';

export function localUtcDate(): Moment {
  return moment(moment.utc()).local();
}

export function convertUtcToLocal(utcDate: string): Moment {
  return moment(moment.utc(utcDate)).local();
}

export function formatLocal(utcDate: string, format: string): string {
  return moment(utcDate).local().format(format);
}

export function formatUtcToLocal(utcDate: string, format: string): string {
  return moment(moment.utc(utcDate)).local().format(format);
}

export function formatUtcToLocalDateTime(utcDate: string): string {
  return moment(moment.utc(utcDate)).local().format('dddd, MMM Do YYYY @ h:mm a');
}
