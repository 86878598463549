import { Box, Stack, Typography } from '@mui/material';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { usePreviewProvider } from './use-preview-provider';

const PreviewFile = () => {
  const { displayFile } = usePreviewProvider();

  const filePreview = () => {
    switch (displayFile?.fileType) {
      case 'image':
        return <img src={displayFile.fileDataUrl} alt={displayFile.fileName} height={600} width={600} />;
      case 'video':
        return (
          <video controls width={300}>
            <source src={displayFile.fileDataUrl} type={displayFile.contentType} />
          </video>
        );
      case 'text':
        return <object data={displayFile.fileDataUrl} aria-label={displayFile.fileName} height={600} width={600}></object>;
      case 'pdf':
        return <object data={displayFile.fileDataUrl} aria-label={displayFile.fileName} height={700} width={800}></object>;
      default:
        return (
          <Stack height={600} width={600} alignItems="center" justifyContent="center" spacing={3}>
            <ContentPasteOffIcon sx={{ fontSize: 35 }} />
            <Typography>File preview not available</Typography>
          </Stack>
        );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {filePreview()}
    </Box>
  );
};

export default PreviewFile;
