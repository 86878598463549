import { Alert, AlertTitle, Backdrop, Button, Fade, Grid, Modal, Stack } from '@mui/material';
import MainCard from 'components/MainCard';
import { dispatch, setErrorMessage, useSelector } from 'store';

const ErrorModal: React.VFC = () => {
  const { errorMessage } = useSelector((state) => state.root);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={errorMessage !== ''}
      onClose={() => dispatch(setErrorMessage(''))}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={errorMessage !== ''}>
        <MainCard title="Exception" modal darkTitle content={false} sx={{ maxWidth: '500px' }}>
          <Grid container rowSpacing={5}>
            <Grid item xs={12}>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Button onClick={() => dispatch(setErrorMessage(''))}>Ok</Button>
            </Stack>
          </Grid>
        </MainCard>
      </Fade>
    </Modal>
  );
};

export default ErrorModal;
