import { useEffect, useState } from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import MainCard from 'components/MainCard';
import { useCurrentWidth } from 'hooks';
import { useTheme } from '@mui/material/styles';

interface IFilterBaseButtonProps {
  onClick?: () => void;
  title: string;
  iconPrimary?: any;
  color?: string;
  filterCount: number;
  isActive?: boolean;
}

interface IBlockSizeData {
  iconFontSize: number;
  itemCountFontSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  itemTitleFontSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  itemLeftPadding: number;
}

const defaultBlockSizeData: IBlockSizeData = { iconFontSize: 25, itemCountFontSize: 'h2', itemTitleFontSize: 'h4', itemLeftPadding: 3 };

const FilterBaseButton = ({ filterCount, title, isActive, iconPrimary, color, onClick }: IFilterBaseButtonProps) => {
  const IconPrimary = iconPrimary!;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;
  const browserWidth = useCurrentWidth();
  const [blockSizeData, setBlockSizeData] = useState<IBlockSizeData>(defaultBlockSizeData);
  const theme = useTheme();

  useEffect(() => {
    if (browserWidth <= theme.breakpoints.values.lg) {
      setBlockSizeData({
        iconFontSize: 36,
        itemCountFontSize: 'h2',
        itemTitleFontSize: 'h5',
        itemLeftPadding: 1
      });
    } else {
      setBlockSizeData(defaultBlockSizeData);
    }
  }, [browserWidth, theme.breakpoints.values]);

  return (
    <AnimateButton>
      <Paper elevation={isActive ? 10 : 0} style={!isActive ? { opacity: 0.6 } : { opacity: 1 }}>
        <MainCard
          content={false}
          onClick={onClick}
          sx={{
            bgcolor: color,
            position: 'relative',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8
            },
            borderRadius: '5px',
            '&:before, &:after': {
              content: '""',
              width: 1,
              height: 1,
              position: 'absolute',
              background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.0001) 22.07%, rgba(255, 255, 255, 0.15) 83.21%)',
              transform: 'matrix(0.9, 0.44, -0.44, 0.9, 0, 0)'
            },
            '&:after': {
              top: '50%',
              right: '-20px'
            },
            '&:before': {
              right: '-70px',
              bottom: '80%'
            }
          }}
        >
          <Box sx={{ px: blockSizeData.itemLeftPadding, py: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12}>
                <Stack
                  spacing={1}
                  alignItems="center"
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                  <Typography variant={blockSizeData.itemCountFontSize} color="common.white">
                    {filterCount}
                  </Typography>
                  <Typography variant={blockSizeData.itemTitleFontSize} color="common.white" sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                    {title}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    color: '#fff',
                    opacity: 0.5,
                    fontSize: blockSizeData.iconFontSize,
                    lineHeight: 0,
                    position: 'absolute',
                    right: '20px',
                    top: '10px'
                  }}
                >
                  {primaryIcon}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </MainCard>
      </Paper>
    </AnimateButton>
  );
};

export default FilterBaseButton;
