// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

// THEME project import - THESE WILL GO AWAY
import menu from './menu/menu';
import snackbar from './snackbar/snackbar';
import rootReducer from './root/root.slice';
import member from './member/member.slice';
import appointments from './appointment/appointment.slice';
import labs from './labs/labs.slice';
import feedback from './feedback/feedback.slice';
import encounter from './encounter/encounter.slice';

// ==============================|| COMBINE REDUCERS ||============================== //
const rootPersistConfig = {
  key: 'root',
  storage: storageSession,
  keyPrefix: 'nav-root-',
  blacklist: ['isRootLoading']
};

const reducers = combineReducers({
  root: persistReducer(rootPersistConfig, rootReducer),
  menu,
  snackbar,
  member,
  appointments,
  labs,
  feedback,
  encounter
});

export default reducers;
