import { FileInput, FileInputButton, FileInputIcon } from './FileInput';
import UploadProvider, { UploadProviderProps } from './UploadProvider';
import { UploadControllerProps } from './use-upload-controller';

export const UploadFileIcon: React.VFC<Omit<UploadProviderProps, 'children'>> = (props: UploadControllerProps) => {
  return (
    <UploadProvider {...props}>
      <FileInput />
      <FileInputIcon />
    </UploadProvider>
  );
};

export const UploadFileButton: React.VFC<Omit<UploadProviderProps, 'children'>> = (props: UploadControllerProps) => {
  return (
    <UploadProvider {...props}>
      <FileInput />
      <FileInputButton />
    </UploadProvider>
  );
};
