import { Button } from '@mui/material';
import { MdAttachFile } from 'react-icons/md';
import { useUploadProvider } from './use-upload-provider';

export const FileInput = () => {
  const { inputFile, handleFilesUploaded } = useUploadProvider();

  return (
    <input
      title="File"
      type="file"
      id="files"
      name="files"
      ref={inputFile}
      style={{ display: 'none' }}
      multiple
      onChange={handleFilesUploaded}
    />
  );
};

export const FileInputIcon = () => {
  const { onUpload } = useUploadProvider();

  return <MdAttachFile onClick={onUpload} style={{ cursor: 'pointer', fontSize: '1.5em' }} />;
};

export const FileInputButton = () => {
  const { onUpload } = useUploadProvider();

  return (
    <Button variant="outlined" color="secondary" style={{ cursor: 'pointer' }} onClick={onUpload}>
      Upload
    </Button>
  );
};
