import React from 'react';
import Routes from 'routes';
import ScrollTop from 'components/ScrollTop';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ThemeCustomization from 'themes';
import { ReactQueryClientProvider } from 'components/ReactQueryClientProvider';
import { SoundEffectsProvider } from '../../packages/sound-effects';
import { FileProvider } from 'packages/file-handling';
import { ModalStack } from 'sollishealth.core.react';
import { composeProvider } from 'react-compose-provider';
import { FeaturesProvider } from 'features/feature-flags';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const AppProvider = composeProvider(
  ReactQueryClientProvider,
  FeaturesProvider,
  SoundEffectsProvider,
  FileProvider,
  ThemeCustomization,
  ModalStack,
  RTLLayout,
  Locales,
  ScrollTop
);

const App: React.FC = () => {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
};
export default App;
