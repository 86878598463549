import { styled } from '@mui/material/styles';
import { Paper, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses } from '@mui/material';
import globalSearchContext from './use-global-search';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0
    }
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent'
  }
}));

const SearchToggleButton = () => {
  const { searchType, setSearchType } = globalSearchContext.useController();

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap'
        }}
      >
        <StyledToggleButtonGroup color="primary" value={searchType} exclusive onChange={(_event, value) => setSearchType(value)}>
          <ToggleButton value="member">Member</ToggleButton>
          <ToggleButton value="task">Task</ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default SearchToggleButton;
