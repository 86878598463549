import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Box, ClickAwayListener, Paper, Popper, Tooltip, useMediaQuery } from '@mui/material';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { IActivity } from 'types';
import { useOnUpdateEffect, useSoundEffects } from 'hooks';
import { activitySignalr } from 'hooks/use-activity-signalr';
import useSessionStorageState from 'use-session-storage-state';
import { ActivityList } from 'components';

const Notification = () => {
  const { newActivity } = activitySignalr.useController();
  const [notifications, setNotifications] = useSessionStorageState<IActivity[]>('notifications', { defaultValue: [] });
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const soundEffects = useSoundEffects();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  //  Only play notification when there are items!
  useOnUpdateEffect(() => {
    if (newActivity) {
      soundEffects.invokeNotificationSound();
      setNotifications((existingItems) => [newActivity, ...existingItems]);
    }
  }, [newActivity]);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={() => setOpen(!open)}
      >
        <Badge badgeContent={notifications.length} color={'primary'}>
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 400,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title={notifications.length > 0 ? 'Notifications' : 'No New Notifications'}
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {notifications.length > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              setNotifications([]);
                              handleToggle();
                            }}
                          >
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  {/* {notifications.length > 0 && (
                    <Grid item xs={12} style={{ padding: '10px' }}>
                      <Grid container rowSpacing={3} spacing={3} alignItems="center" style={{ maxHeight: '450px', overflowY: 'auto' }}>
                        {notifications.map((item: any, index: number) => (
                          <ActivityItem {...item} key={index} />
                        ))}
                      </Grid>
                    </Grid>
                  )} */}
                  <ActivityList activities={notifications} />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
