import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { loggedInUser } from 'hooks';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const SummaryTeamSelector = () => {
  const { myTeams } = loggedInUser.useController();
  const { dashboardTeamId, setDashbaordTeam } = dashboardContext.useController();

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Typography variant="h5">Viewing tasks for</Typography>
      <Select
        variant="standard"
        id="myTeams"
        label="MyTeam"
        value={dashboardTeamId}
        onChange={(g) => setDashbaordTeam(g.target.value as string)}
      >
        {myTeams.length > 1 && (
          <MenuItem key={'ALL'} value={'ALL'}>
            {'All My Teams'}
          </MenuItem>
        )}
        {myTeams?.map((t) => {
          return (
            <MenuItem key={t.id} value={t.id}>
              {t.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default SummaryTeamSelector;
