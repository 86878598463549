// project import
import { NavItemType } from 'types/store/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: []
};

export default menuItems;
