import { SearchMemberProps, useSearchMembers } from './use-search-members';
import { SearchAndShow } from '../components/SearchAndShow';
import { SearchStringProvider } from '../search-string-provider';

const Wrapper = (props: SearchMemberProps) => {
  const { id, placeHolder, value, searchOptions, allowFreeText, onSelect, isDisabled, errors, touched, showLoading } =
    useSearchMembers(props);

  return (
    <SearchAndShow
      id={id}
      placeHolder={placeHolder!}
      allowFreeText={allowFreeText}
      isDisabled={isDisabled}
      onSelect={onSelect}
      value={value}
      searchOptions={searchOptions}
      errors={errors}
      touched={touched}
      showLoading={showLoading}
    />
  );
};

export const SearchMembers = (props: SearchMemberProps) => {
  return (
    <SearchStringProvider>
      <Wrapper {...props} />
    </SearchStringProvider>
  );
};
