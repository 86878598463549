import useApiDataService from 'hooks/services/use-api-data-service';
import { ApiService } from 'services';
import { IMember } from 'types';

export interface IReferral {
    id: number;
    encounterId: number;
    memberId: IMember['memberId'];
    status: string;
    documentType: string;
    description: string;
    clinicalProviderId?: number;
    documentTypeId?: number;
    documentRoute: string;
    createdDateTime: string;
    lastModifiedDateTime: string;
    departmentId: number;
    providerId: number;
    priority: number;
    taskId?: string;
}

export class EncountersService {
    protected apiService: ApiService;

    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    getMemberReferrals(memberId: IMember['memberId']): Promise<IReferral[]> {
        return this.apiService.get(`Encounters/v1/Referral/member/${memberId}`).then((r) => r?.data);
    }
}

export const useEncounterService = () => useApiDataService<EncountersService>(EncountersService);
