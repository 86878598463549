import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import appSettings from '../constants/appsettings.json';
import { msalInstance } from 'contexts/Provider';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 60000 * idleTime * 60 * 8;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    const currentAccount = msalInstance.getAccountByHomeId(appSettings.auth.tenantId);
    msalInstance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: appSettings.auth.postLogoutRedirectUri,
      onRedirectNavigate: () => {
        return false;
      }
    });
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500
  });

  return {
    isIdle,
    setIdle,
    idleTimer
  };
};

export default useIdleTimeout;
