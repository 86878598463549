import { FileController, IFile, PreviewState } from './definitions';
import { useState } from 'react';

export const useFileProvider = (): FileController => {
  const [previewState, setPreviewState] = useState<PreviewState | undefined>(undefined);

  const previewFiles = (previewFileList: IFile[], previewFileIndex?: number) => {
    const fileList = previewFileList.map((f) => {
      let fileType = '';
      if (f.contentType.toLowerCase().includes('image')) {
        fileType = 'image';
      } else if (f.contentType.toLowerCase().includes('video')) {
        fileType = 'video';
      } else if (f.contentType.toLowerCase().includes('text')) {
        fileType = 'text';
      } else if (f.contentType.toLowerCase().includes('pdf')) {
        fileType = 'pdf';
      }

      const cleanContentBytes = f.contentBytes!.replace(/^data:.+;base64,/, '');
      const byteCharacters = atob(cleanContentBytes);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const fileDataUrl = URL.createObjectURL(new Blob([byteArray], { type: f.contentType }));

      return {
        ...f,
        fileType,
        fileDataUrl
      } as IFile;
    });

    setPreviewState({ fileList, selectedFile: fileList[previewFileIndex ?? 0] });
  };

  const clearPreview = () => {
    setPreviewState(undefined);
  };

  return {
    previewState,
    previewFiles,
    clearPreview
  };
};

export default useFileProvider;
