import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import dashboardContext from 'views/Dashboard/useDashboardContext';

const ReloadTasks: React.VFC = () => {
  const { isTasksRefreshing, reloadTasks } = dashboardContext.useController();

  const handleClick = () => {
    if (!isTasksRefreshing) {
      reloadTasks();
    }
  };

  return (
    <Tooltip title={isTasksRefreshing ? 'Refreshing' : 'Reload'}>
      <IconButton aria-label="refresh" onClick={handleClick}>
        {isTasksRefreshing ? <CircularProgress size={24} /> : <RefreshIcon fontSize="inherit" />}
      </IconButton>
    </Tooltip>
  );
};

export default ReloadTasks;
