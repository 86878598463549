export enum activityTypeEnum {
  Comment = 'Comment',
  Mention = 'Mention',
  Assignment = 'Assignment',
  Responded = 'Responded',
  Updated = 'Updated',
  Reminder = 'Reminder',
  Created = 'Created',
  Closed = 'Closed',
  ReOpened = 'ReOpened',
  SmsFailed = 'SmsFailed'
}

export enum activityFilterTypeEnum {
  Comment = 'Comment',
  Mention = 'Mention',
  Assignment = 'Assignment',
  Responded = 'Responded',
  Reminder = 'Reminder',
  Closed = 'Closed',
  ReOpened = 'ReOpened'
}
