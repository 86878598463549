import { useMemo } from 'react';
import { MILLISECONDS, IMember } from 'types';
import { useMemberService } from '../../../hooks';
import { SearchAndShowProps, SearchOption } from 'packages/search-and-show';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useSearchString } from '../search-string-provider';

export interface SearchMemberProps {
  id: string;
  placeholder: string;
  memberId?: number;
  currentOption?: string | IMember;
  disabled?: boolean;
  allowFreeText?: boolean;
  errors?: any;
  touched?: any;
  onSelect: (event: any) => void;
}

export interface SearchMemberContext {
  id: string;
  placeholder: string;
  currentOption?: string | SearchOption;
  disabled?: boolean;
  allowFreeText?: boolean;
  errors?: any;
  touched?: any;
  handleSearch?: (searchValue: string) => Promise<SearchOption[]>;
  onSelect: (event: any) => void;
}

export const useSearchMembers = (props: SearchMemberProps): SearchAndShowProps => {
  const { id, placeholder, memberId, allowFreeText = false, currentOption, onSelect, disabled, errors, touched } = props;
  const { debouncedSearchString } = useSearchString();
  const memberService = useMemberService();

  const currentMemberOption = useMemo(() => {
    return !currentOption
      ? undefined
      : typeof currentOption === 'string'
      ? currentOption
      : ({
          id: `${memberId}`,
          displayName: currentOption.fullName,
          optionObject: currentOption
        } as SearchOption);
  }, [currentOption]);

  const searchQuery = useQuery({
    queryKey: ['searchMembers', debouncedSearchString],
    queryFn: () => memberService.findMembers(debouncedSearchString),
    select: (data) =>
      data
        .map((memberOption, index) => {
          return {
            id: `${memberOption.memberId}${index}`,
            displayName: memberOption.fullName,
            optionalDisplayName: memberOption.dateOfBirth ? moment(memberOption.dateOfBirth).format('MM-DD-YYYY') : undefined,
            optionDisabled: memberOption.memberId === memberId,
            optionObject: memberOption
          } as SearchOption;
        })
        .sort((x: SearchOption, y: SearchOption) => {
          let a = x.displayName.toUpperCase(),
            b = y.displayName.toUpperCase();
          return a === b ? 0 : a > b ? 1 : -1;
        }),
    enabled: Boolean(debouncedSearchString && debouncedSearchString.length >= 2),
    staleTime: MILLISECONDS.MINUTE * 15
  });

  const showLoading = (searchQuery.isPending && debouncedSearchString?.length >= 2) || searchQuery.isLoading;

  return {
    id,
    placeHolder: placeholder,
    allowFreeText,
    value: currentMemberOption,
    searchOptions: searchQuery?.data,
    onSelect,
    isDisabled: disabled,
    errors,
    touched,
    showLoading
  };
};
