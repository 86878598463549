import { createSlice } from '@reduxjs/toolkit';
import { IMemberFeedbackState } from 'types';

const initialState: IMemberFeedbackState = {
  isLoadingDetails: false,
  memberFeedbacks: []
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    isLoadingFeedback(state, action) {
      return {
        ...state,
        isLoadingDetails: action.payload
      };
    },
    loadFeedback(state, action) {
      return {
        ...state,
        isLoadingDetails: false,
        memberFeedbacks: action.payload
      };
    }
  }
});

export default slice.reducer;
export const { isLoadingFeedback, loadFeedback } = slice.actions;
