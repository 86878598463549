import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import spinner from 'assets/spinner.gif';

const MemberComponentLoaderWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: '2000',
  backgroundColor: '#ffffff',
  opacity: '0.7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0
}));

export interface MemberComponentLoaderProps {
  isLoading: boolean;
  loadingMessage?: string;
}

const MemberComponentLoader: React.VFC<MemberComponentLoaderProps> = (props) => {
  return props.isLoading ? (
    <MemberComponentLoaderWrapper>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
        <img src={spinner} id="loading-spinner" alt="Loading..." width="100" />
        {props.loadingMessage && <Typography variant="h5">{props.loadingMessage}</Typography>}
      </Stack>
    </MemberComponentLoaderWrapper>
  ) : null;
};

export default MemberComponentLoader;
