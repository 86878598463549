import { loggedInUser, useTaskService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useTaskStatusesCache = () => {
  const taskService = useTaskService();
  const { isLoggedIn } = loggedInUser.useController();

  const query = useQuery({
    queryKey: ['taskStatuses'],
    queryFn: () => taskService.getTaskStatuses(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isTaskStatusesLoading, data: taskStatuses = [] } = query;

  return {
    isTaskStatusesLoading,
    taskStatuses,
    hasTaskStatuses: taskStatuses.length > 0
  };
};
