import { loggedInUser, useTaskService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useTaskArchiveTimingsCache = () => {
  const taskService = useTaskService();
  const { isLoggedIn } = loggedInUser.useController();

  const query = useQuery({
    queryKey: ['archiveTimings'],
    queryFn: () => taskService.getTaskArchiveTimings(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isArchiveTimingsLoading, data: taskArchiveTimings = [] } = query;

  return {
    isArchiveTimingsLoading,
    taskArchiveTimings,
    hasArchiveTimings: taskArchiveTimings.length > 0
  };
};
