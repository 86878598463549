import useLocalStorage from 'use-local-storage';
import { SoundEffectsController } from './definitions';

export const useSoundEffectsProvider = (): SoundEffectsController => {
  const [isMuted, setMuted] = useLocalStorage<boolean>('mute-state', false);
  //  Keep track of date in the event we might want to revoke mute after a specified time period (tbd later on perhaps? regardless, nice to have)
  const [, setUpdatedDate] = useLocalStorage<string>('mute-state-updated-date', '');

  const toggleMute = () => {
    setMuted(!isMuted);
    setUpdatedDate(new Date().toString());
  };

  return {
    isMuted,
    toggleMute
  };
};

export default useSoundEffectsProvider;
