import { api } from 'utils';
import { IRequestConfig } from 'types';
import { dispatch } from 'store';
import settings from 'constants/appsettings.json';
import { isLoadingDetails, loadDetails } from './labs.slice';

export function labs_loadDetails(labId: number) {
  dispatch(isLoadingDetails(true));

  const onResolve = async (res: any) => {
    const data = await res.data;
    dispatch(loadDetails(data));
  };

  const onReject = () => {
    dispatch(isLoadingDetails(false));
  };

  const url = `${settings.apiDetails.baseUri}Labs/v1/details/${labId}`;
  const config: IRequestConfig = {
    method: 'get',
    url: url,
    onResolve,
    onReject
  };
  return api.processRequest(config);
}

export function labs_clearDetails() {
  dispatch(isLoadingDetails(false));
  dispatch(loadDetails([]));
}
