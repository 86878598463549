import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';

// types
import { RootStateProps } from 'types/root';
import { openDrawer } from 'store/reducers/menu/menu';
import LogoutModal from 'components/LogoutModal';
import OutOfDateModal from 'components/OutOfDateModal';
import appSettings from 'constants/appsettings.json';
import ErrorModal from 'components/ErrorModal';
import ActionSnackBar from 'components/ActionSnackBar';
import { FileViewerModal } from 'packages/file-handling';
import useIdle from 'hooks/useIdleTimeout';
import { CachePreloader, DataDogUser, loggedInUser, useConfig, useTaskService } from 'hooks';
import { activitySignalr } from 'hooks/use-activity-signalr';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const dispatch = useDispatch();
  const taskService = useTaskService();

  const { container, miniDrawer } = useConfig();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    const intervalTimeOut = 1000 * 60 * appSettings.constants.taskChatPurgeInMinutes;
    setInterval(() => {
      taskService.cleanupTaskChats();
    }, intervalTimeOut);
  }, []);

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const handleIdle = () => {};
  const { idleTimer } = useIdle({ onIdle: handleIdle, idleTime: 1 });

  idleTimer.start();

  return (
    <loggedInUser.Provider>
      <activitySignalr.Provider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Header open={open} handleDrawerToggle={handleDrawerToggle} />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <Toolbar />
            {container && (
              <Container
                maxWidth="xl"
                sx={{
                  px: { xs: 0, sm: 2 },
                  position: 'relative',
                  minHeight: 'calc(100vh - 110px)',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Outlet />
                <Footer />
              </Container>
            )}
            {!container && (
              <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
                <Outlet />
                <Footer />
              </Box>
            )}
          </Box>
        </Box>
        <CachePreloader />
        <DataDogUser />
        <FileViewerModal />
        <LogoutModal />
        <OutOfDateModal />
        <ErrorModal />
        <ActionSnackBar />
      </activitySignalr.Provider>
    </loggedInUser.Provider>
  );
};

export default MainLayout;
