import { Stack } from '@mui/material';
import activityContext from '../../use-recent-activity';
import { ActivityDateFilter } from './ActivityDateFilter';
import { ActivityTeamFilter } from './ActivityTeamFilter';
import { ActivityTypeFilter } from './ActivityTypeFilter';

export const FilterActivity = () => {
  const { showFilters } = activityContext.useController();

  return (
    <Stack direction="row" spacing={2} sx={{ pt: '6px', display: showFilters ? 'flex' : 'none' }}>
      <ActivityTypeFilter />
      <ActivityTeamFilter />
      <ActivityDateFilter />
    </Stack>
  );
};
