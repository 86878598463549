import { SearchAndShow } from '../components/SearchAndShow';
import { SearchProps } from '../definitions';
import { SearchStringProvider } from '../search-string-provider';
import { useSearchUsers } from './use-search-users';
import { Chip } from '@mui/material';
import UserAvatar from 'components/UserAvatar';

const Wrapper = (props: SearchProps) => {
  const { id, value, onSelect, searchOptions, isDisabled, showLoading } = useSearchUsers(props);

  return (
    <SearchAndShow
      id={id}
      placeHolder="Add Participant..."
      allowMultiple={true}
      isDisabled={isDisabled}
      onSelect={onSelect}
      value={value}
      showLoading={showLoading}
      searchOptions={searchOptions}
      renderTags={(values, getTagProps) =>
        values.map((value: any, index: any) => (
          <Chip avatar={<UserAvatar displayName={value.displayName} size={'md'} />} variant="outlined" {...getTagProps({ index })} />
        ))
      }
    />
  );
};

export const SearchUsers = (props: SearchProps) => {
  return (
    <SearchStringProvider>
      <Wrapper {...props} />
    </SearchStringProvider>
  );
};
