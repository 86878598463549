import { loggedInUser } from 'hooks';
import { useNavigate } from 'react-router';
import { dispatch, resetMemberState } from 'store';
import { IActivity } from 'types';
import { activityTypeEnum } from 'types/store/activityEnums';

export const useActivityItem = (activityItem: IActivity) => {
  const { userName } = loggedInUser.useController();
  const navigate = useNavigate();

  const { activityDate, activityInitiatorName, activityTypeName, userId, teamId, teamName, taskId, taskName } = activityItem;

  let activityMessage = activityItem.message === undefined ? activityItem.activityTypeName : activityItem.message;
  activityMessage = activityMessage.replace(/(<([^>]+)>)/gi, '');

  let activityAvatarDisplayName = activityInitiatorName;
  if (activityTypeName === activityTypeEnum.Reminder) {
    activityAvatarDisplayName = teamName ?? userName ?? activityInitiatorName;
  }

  const activityDescription =
    activityTypeName === activityTypeEnum.Mention && !teamId
      ? `${activityInitiatorName} has mentioned you on`
      : activityTypeName === activityTypeEnum.Mention && teamId
      ? `${activityInitiatorName} has mentioned ${teamName} on`
      : activityTypeName === activityTypeEnum.Assignment && !teamId
      ? `${activityInitiatorName} has assigned you to`
      : activityTypeName === activityTypeEnum.Assignment && !userId
      ? `${activityInitiatorName} has assigned ${teamName} to`
      : activityTypeName === activityTypeEnum.Responded
      ? `${activityInitiatorName} has responded to`
      : activityTypeName === activityTypeEnum.Updated
      ? `${activityInitiatorName} has updated`
      : activityTypeName === activityTypeEnum.Closed
      ? `${activityInitiatorName} has closed`
      : activityTypeName === activityTypeEnum.ReOpened
      ? `${activityInitiatorName} has reopened`
      : activityTypeName === activityTypeEnum.Reminder && !teamId
      ? `You have a reminder for`
      : activityTypeName === activityTypeEnum.Reminder && !userId
      ? `${teamName} has a reminder for`
      : activityTypeName === activityTypeEnum.Created
      ? `${activityInitiatorName} has created`
      : activityTypeName === activityTypeEnum.SmsFailed
      ? `SMS Failure received on`
      : `${activityInitiatorName} has commented on`;

  const handleTaskLink = () => {
    dispatch(resetMemberState());
    navigate(`/task`, {
      state: {
        paramsData: {
          taskId: taskId
        }
      }
    });
  };

  return {
    activityAvatarDisplayName,
    activityDescription,
    activityMessage,
    activityDate,
    taskName,
    handleTaskLink
  };
};
