import EmailIcon from '@mui/icons-material/Email';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import MessageIcon from '@mui/icons-material/Message';
import FilterBaseButton from './FilterBaseButton';
import taskFiltersContext from '../../../use-task-filters';
import { highlightColorEnum } from 'components/TextHighlight';

export const EmailFilter = () => {
  const { emailCounts, useEmailFilter, handleEmailFilter } = taskFiltersContext.useController();

  return (
    <FilterBaseButton
      filterCount={emailCounts}
      onClick={handleEmailFilter}
      title="Has Email"
      iconPrimary={EmailIcon}
      color={highlightColorEnum.FilterButtonGray}
      isActive={useEmailFilter}
    />
  );
};

export const ReplyFilter = () => {
  const { replyCounts, useReplyFilter, handleReplyFilter } = taskFiltersContext.useController();

  return (
    <FilterBaseButton
      filterCount={replyCounts}
      onClick={handleReplyFilter}
      title="Has Reply"
      iconPrimary={ReplyAllIcon}
      color={highlightColorEnum.FilterButtonGray}
      isActive={useReplyFilter}
    />
  );
};

export const SmsFilter = () => {
  const { smsCounts, useSmsFilter, handleSmsFilter } = taskFiltersContext.useController();

  return (
    <FilterBaseButton
      filterCount={smsCounts}
      onClick={handleSmsFilter}
      title="Has SMS"
      iconPrimary={MessageIcon}
      color={highlightColorEnum.FilterButtonGray}
      isActive={useSmsFilter}
    />
  );
};

export const UnassignedFilter = () => {
  const { unassignedCounts, useUnassignedFilter, handleUnassignedFilter } = taskFiltersContext.useController();

  return (
    <FilterBaseButton
      filterCount={unassignedCounts}
      onClick={handleUnassignedFilter}
      title="Unassigned"
      color={highlightColorEnum.FilterButtonGray}
      isActive={useUnassignedFilter}
    />
  );
};
