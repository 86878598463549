import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

// types
import { GuardProps } from 'types';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const isLoggedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const paramsData = useParams();

  useEffect(() => {
    if (!isLoggedIn) {
      let navigateObject = {
        replace: true
      } as any;
      if (paramsData && paramsData.taskId) {
        navigateObject = {
          ...navigateObject,
          state: {
            paramsData
          }
        };
      }

      navigate('/login', navigateObject);
    } else if (isLoggedIn && paramsData && paramsData.taskId && !paramsData.memberId) {
      navigate(`/task`, {
        replace: true,
        state: {
          paramsData: paramsData
        }
      });
    } else if (isLoggedIn && paramsData && paramsData.memberId) {
      navigate(`/member`, {
        replace: true,
        state: {
          paramsData: paramsData
        }
      });
    }
  }, [isLoggedIn, paramsData, navigate]);

  if (!isLoggedIn) {
    return <></>;
  }
  return <>{children}</>;
};

export default AuthGuard;
