import { activityFilterTypeEnum } from 'types/store/activityEnums';
import activityContext from '../../use-recent-activity';
import { useMemo } from 'react';
import _ from 'lodash';
import { FilterOptionsSelect } from 'components';

export const ActivityTypeFilter = () => {
  const { filterTypes, handleFilterTypes } = activityContext.useController();
  const activityTypes = _.sortBy(Object.keys(activityFilterTypeEnum));
  const filterTypeOptions = useMemo(
    () =>
      activityTypes.map((t) => {
        return { id: t, displayName: t };
      }),
    [activityTypes]
  );

  return (
    <FilterOptionsSelect
      allowMultiples={true}
      currentChoices={filterTypes}
      filterName="Filter Types"
      filterOptions={filterTypeOptions}
      onChange={handleFilterTypes}
    />
  );
};
