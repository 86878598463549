export enum SearchFilterField {
    name = 'name',
    speciality = 'speciality',
    city = 'city',
    state = 'state',
    country = 'country',
    affiliation = 'affiliation',
    insurance = 'insurance',
    hamptons = 'hamptons',
    locations = 'locations',
    pageIncrement = 'pageIncrement',
}

export interface ISelectorOption {
    label: string;
    value: string;
}