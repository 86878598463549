import {
  FormControl,
  SelectProps,
  Select,
  Box,
  OutlinedInput,
  Chip,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { first } from 'lodash';
import React from 'react';

type Value = string | number;
type Label = string;

export type MultiObjectSelectorProps<Type extends object> = SelectProps &  {
  options: Type[];
  values: Value[];
  onChange?: (v: Value[]) => void;
  //  returns label for an object
  onGetLabel: (v: Type) => Label;
  //  returns value for an object
  onGetValue: (v: Type) => Value;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiObjectSelector = <Type extends object>({
  options = [],
  values = [],
  onChange = () => {},
  onGetLabel: getLabel,
  onGetValue: getValue,
  ...props
}: MultiObjectSelectorProps<Type>) => {
  const theme = useTheme();

  //  Find a label using the value
  const findValueLabel = React.useCallback((value: Value) => {
    const option = first(options.filter(o => getValue(o) === value));
    return option ? getLabel(option) : '';
  }, [options]);

  //  The chips that get rendered when things are selected
  const renderValue = (values: Value[]) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {values.map((value) => (
        <Chip key={value} label={findValueLabel(value)} />
      ))}
    </Box>
  );

  const handleChange = (event: SelectChangeEvent<Value[]>) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <FormControl fullWidth={props.fullWidth}>
      <Select
        fullWidth
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput/>}
        renderValue={renderValue}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={`${getValue(option)}-${getLabel(option)}`}
            value={getValue(option)}
            style={{
              fontWeight: (values || []).includes(getValue(option))
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular
            }}
          >
            {getLabel(option)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiObjectSelector;
