import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { member_loadMemberTask } from 'store';
import MemberComponentLoader from 'views/Member/components/MemberComponentLoader';

const Task: React.VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let taskId: string | null = null;

  if (location && location.state) {
    taskId = location.state.paramsData?.taskId;
  }

  useEffect(() => {
    try {
      if (!taskId) {
        navigate('/dashboard', { replace: true });
      } else {
        member_loadMemberTask(taskId, true).then((memberId) => {
          navigate(`/member`, {
            state: {
              paramsData: {
                taskId: taskId,
                memberId: memberId
              }
            }
          });
        });
      }
    } catch (err) {
      navigate('/dashboard', { replace: true });
    }
  }, [taskId]);

  return <MemberComponentLoader isLoading={true} />;
};

export default Task;
