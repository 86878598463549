// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';
import { Grid, Stack } from '@mui/material';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack flexDirection={'column'}>
        <Grid item style={{ verticalAlign: 'top' }}>
          <Logo isIcon={!open} sx={{ visibility: open ? 'visible' : 'hidden', width: open ? 'auto' : 35, verticalAlign: 'top' }} />
        </Grid>
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
