import { Backdrop, Fade, Modal, Grid, Stack, Button, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { useState } from 'react';
import { ITaskAssignedTo, ITask } from 'types';
import AssignTo from 'views/Member/components/TaskDetails/form/AssignOwner/AssignTo';
import { loggedInUser } from 'hooks';
import MemberComponentLoader from 'views/Member/components/MemberComponentLoader';
import { dispatch, setErrorMessage } from 'store';
import { useMutation } from '@tanstack/react-query';
import dashboardContext from 'views/Dashboard/useDashboardContext';

export interface IAssignOwnerModalProps {
  open: boolean;
  onClose: () => void;
  task: ITask | null;
}

const AssignOwnerModal: React.VFC<IAssignOwnerModalProps> = (props) => {
  const { userId } = loggedInUser.useController();
  const { handleUpdateAssignedTo } = dashboardContext.useController();
  const { open, onClose, task } = props;
  const taskId = task?.taskId ?? '';
  const [assignedTo, setAssignedTo] = useState<ITaskAssignedTo | null>(null);

  const currentAssignedTo = {
    displayName: task?.assignedToDisplayName ?? 'unassigned',
    userId: task?.assignedToUserId,
    groupId: task?.assignedToGroupId
  };

  const assignMutate = useMutation({
    mutationFn: () => {
      const payload = {
        assignedToGroupId: assignedTo?.groupId || '',
        assignedToUserId: assignedTo?.userId,
        taskId
      };
      return handleUpdateAssignedTo(payload);
    },
    onSuccess() {
      onClose();
    },
    onError(error) {
      dispatch(setErrorMessage(error.message ?? 'There was a problem.'));
    }
  });
  const handleAssign = assignMutate.mutate;

  const onSelect = (event: any) => {
    //  Need to massage the data a bit because -somebody- used `any` (you know who you are ;-)
    const value = event.target.value || {};
    const assignedTo: ITaskAssignedTo | null = !value
      ? null
      : {
          displayName: value.displayName,
          userId: value.userId,
          groupId: value.groupId
        };
    setAssignedTo(assignedTo);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <MainCard modal darkTitle content={false} sx={{ width: '400px', maxWidth: '400px' }}>
          <Stack spacing={2} padding={2}>
            <Grid container>
              <Grid item xs={4}>
                <Typography>{'Task ID: ' + task?.taskName}</Typography>
              </Grid>
              <Grid item xs={8} textAlign={'left'}>
                <Typography>{'Owner: ' + task?.assignedToDisplayName}</Typography>
              </Grid>
            </Grid>
            <AssignTo
              onSelect={onSelect}
              currentOwner={assignedTo ?? currentAssignedTo}
              showAssignToMe={userId !== (assignedTo ? assignedTo.userId : currentAssignedTo.userId)}
            />
            <Stack spacing={2} direction="row" justifyContent="right" padding={2}>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleAssign()}
                disabled={
                  assignedTo == null ||
                  (currentAssignedTo?.userId === assignedTo?.userId && currentAssignedTo?.groupId === assignedTo?.groupId)
                }
              >
                Assign Task
              </Button>
              <Button size="small" color="secondary" variant="contained" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
          <MemberComponentLoader isLoading={assignMutate.isPending} />
        </MainCard>
      </Fade>
    </Modal>
  );
};

export default AssignOwnerModal;
