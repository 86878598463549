import React from 'react';
import useSearchAndShowController from './use-search-and-show-controller';
import { SearchAndShowControllerContext } from './use-search-and-show-provider';
import { SearchAndShowProps } from './definitions';

export interface SearchAndShowProviderProps extends SearchAndShowProps {
  children: React.ReactNode;
}

export const SearchAndShowProvider = (props: SearchAndShowProviderProps) => {
  const controller = useSearchAndShowController(props);
  return <SearchAndShowControllerContext.Provider value={controller}>{props.children}</SearchAndShowControllerContext.Provider>;
};

export default SearchAndShowProvider;
