import { Stack } from '@mui/material';
import AssignToButton from './AssignToButton';
import AssignToSelector from './AssignToSelector';
import AssignToProvider, { AssignToProviderProps } from './AssignToProvider';

const AssignTo: React.VFC<Omit<AssignToProviderProps, 'children'>> = (props) => {
  return (
    <AssignToProvider {...props}>
      <Stack spacing={1}>
        <AssignToSelector />
        <AssignToButton />
      </Stack>
    </AssignToProvider>
  );
};

export default AssignTo;
