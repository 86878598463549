import Icon from '@mui/icons-material/TextSnippet';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React from 'react';
import { IMedicalPartner } from 'types';
import IconButton from '@mui/material/IconButton';

interface Props {
    record: IMedicalPartner;
}

export const NoteIcon = ({ record }: Props) => {
    const [ visible, setVisibile ] = React.useState(false);
    return !record?.salesForceNote ? null : (
        <ClickAwayListener onClickAway={() => setVisibile(false)}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setVisibile(false)}
                open={visible}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={record.salesForceNote}
              >
                <IconButton onClick={() => setVisibile(!visible)}>
                    <Icon/>
                </IconButton>
              </Tooltip>
            </div>
        </ClickAwayListener>
    );
};
