import React from 'react';
import { PreviewControllerContext } from './use-preview-provider';
import usePreviewController, { PreviewControllerProps } from './use-preview-controller';

export interface PreviewProviderProps extends PreviewControllerProps {
  children: React.ReactNode;
}

export const PreviewProvider = (props: PreviewProviderProps) => {
  const controller = usePreviewController(props);
  return <PreviewControllerContext.Provider value={controller}>{props.children}</PreviewControllerContext.Provider>;
};

export default PreviewProvider;
