import { loggedInUser, useTaskService } from 'hooks';
import { useQuery } from '@tanstack/react-query';

export const useTaskPrioritiesCache = () => {
  const taskService = useTaskService();
  const { isLoggedIn } = loggedInUser.useController();

  const query = useQuery({
    queryKey: ['taskPriorities'],
    queryFn: () => taskService.getTaskPriorities(),
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  const { isLoading: isTaskPrioritiesLoading, data: taskPriorities = [] } = query;

  return {
    isTaskPrioritiesLoading,
    taskPriorities,
    hasTaskPriorities: taskPriorities.length > 0
  };
};
