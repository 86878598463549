import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IMedicalPartner } from 'types';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRight';
import CollapseIcon from '@mui/icons-material/KeyboardArrowDown';
import { PartnerListDetails } from '../layouts/partner-list-item-details';
import { SpecialityChips } from './specialty-chips';
import { Show } from 'components/Show';
import { NoteIcon } from './note-icon';
import { WebsiteIcon } from './website-icon';

export const PartnerListItem = ({ record }: {record: IMedicalPartner}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const AccordionIcon = isOpen ? CollapseIcon : ExpandIcon;
    return (
        <>
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <AccordionIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    {record.fullName}
                </TableCell>
                <TableCell>
                    {record.accountName}
                </TableCell>
                <TableCell>
                    <SpecialityChips record={record} />
                </TableCell>
                <TableCell align="center">{record.insuranceStatus}</TableCell>
                <TableCell align="center" valign='middle'>
                    <Show on={Boolean(record.salesForceNote)}>
                        <NoteIcon record={record} />
                    </Show>
                </TableCell>
                <TableCell align="center">
                    <Show on={Boolean(record.website)}>
                        <WebsiteIcon record={record} />
                    </Show>
                </TableCell>
            </TableRow>
            <PartnerListDetails record={record} visible={isOpen} />
        </>
    );
};