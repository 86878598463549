import { Button } from '@mui/material';
import { useSearchMode } from './search-mode-provider';

interface Props {
    wrapper?: React.FC<React.PropsWithChildren>;
}

export const SearchButton = ({
    wrapper: Wrapper = ({ children }: React.PropsWithChildren) => (<>{children}</>)
}: Props) => {
    const { browseResults, canBrowse } = useSearchMode();
    return (
        <Wrapper>
            <Button
                variant="contained"
                fullWidth
                disabled={!canBrowse}
                onClick={() => browseResults()}
            >
                Search
            </Button>
        </Wrapper>
    );
};
