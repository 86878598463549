import React from 'react';
import { UploadControllerContext } from './use-upload-provider';
import useUploadController, { UploadControllerProps } from './use-upload-controller';

export interface UploadProviderProps extends UploadControllerProps {
  children: React.ReactNode;
}

export const UploadProvider = (props: UploadProviderProps) => {
  const controller = useUploadController(props);
  return <UploadControllerContext.Provider value={controller}>{props.children}</UploadControllerContext.Provider>;
};

export default UploadProvider;
