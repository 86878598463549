import { Stack, ListItem, ListItemText, useTheme, Box } from '@mui/material';
import { usePreviewProvider } from './use-preview-provider';

const PreviewFileList = () => {
  const theme = useTheme();
  const { previewFileList, displayFile, handleFileSelected } = usePreviewProvider();

  return (
    <Box style={{ maxHeight: 600, overflowY: 'auto', display: 'flex', alignItems: 'center' }}>
      <Stack spacing={1}>
        {previewFileList.map((file, index) => {
          return (
            <ListItem
              key={index}
              alignItems="center"
              dense={true}
              sx={{
                bgcolor: file.fileName === displayFile?.fileName ? 'rgba(208, 213, 221, .6)' : theme.palette.grey[100],
                borderBottom: `1px solid ${theme.palette.secondary[200]}`,
                cursor: file.fileName !== displayFile?.fileName ? 'pointer' : 'default',
                '&:hover': {
                  bgcolor: 'rgba(208, 213, 221, .4)'
                }
              }}
              onClick={() => handleFileSelected(file)}
            >
              <ListItemText primary={file.fileName} />
            </ListItem>
          );
        })}
      </Stack>
    </Box>
  );
};

export default PreviewFileList;
