import React from 'react';
import { Grid } from '@mui/material';
import RecentActivity from './components/RecentActivity';
import Summary from './components/Summary';
import TaskGrid from './components/TaskGrid';
import MemberComponentLoader from 'views/Member/components/MemberComponentLoader';
import dashboardContext from './useDashboardContext';
import { loggedInUser } from 'hooks';
import DashboardLoader from './components/DashboardLoader';
import activityContext from './components/RecentActivity/use-recent-activity';
import taskFiltersContext from './components/Summary/use-task-filters';

const Dashboard: React.VFC = () => {
  const { isMyTeamsLoading } = loggedInUser.useController();

  return (
    <>
      {!isMyTeamsLoading && (
        <dashboardContext.Provider>
          <taskFiltersContext.Provider>
            <Grid container rowSpacing={4.5}>
              <Grid item xs={12}>
                <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Summary />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <activityContext.Provider>
                      <RecentActivity />
                    </activityContext.Provider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TaskGrid />
              </Grid>
            </Grid>
            <DashboardLoader />
          </taskFiltersContext.Provider>
        </dashboardContext.Provider>
      )}
      <MemberComponentLoader isLoading={isMyTeamsLoading} />
    </>
  );
};

export default Dashboard;
