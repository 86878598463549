import { Box, Link, Typography } from '@mui/material';
import appSettings from 'constants/appsettings.json';

export const ContactITLink: React.VFC = () => {
  return (
    <Box>
      <Link href={appSettings.navigation.feedbackUrl} underline="hover" target="_blank" rel="noopener noreferrer">
        Contact IT Support
      </Link>
      {` `}
      <Typography sx={{ display: 'inline' }}>for help correcting this.</Typography>
    </Box>
  );
};

export const SuggestContactITLink: React.VFC = () => {
  return (
    <Box>
      <Typography sx={{ display: 'inline' }}>If you feel this is an error, please</Typography>
      {` `}
      <Link href={appSettings.navigation.feedbackUrl} underline="hover" target="_blank" rel="noopener noreferrer">
        Contact IT Support
      </Link>
    </Box>
  );
};

export const PersistsContactITLink: React.VFC = () => {
  return (
    <Box>
      <Typography sx={{ display: 'inline' }}>If this problem persists, please</Typography>
      {` `}
      <Link href={appSettings.navigation.feedbackUrl} underline="hover" target="_blank" rel="noopener noreferrer">
        Contact IT Support
      </Link>
    </Box>
  );
};
