import _ from 'lodash';

export const displayTitle = (title: string) => {
  const capitalizedTitle = _.capitalize(title);
  if (capitalizedTitle.length > 45) {
    return capitalizedTitle.substring(0, 42) + '...';
  }
  return capitalizedTitle;
};

export const highlightSearchText = (text: string, search: string): string => {
  const pattern = search?.replace(/\(/g, '').replace(/\)/g, '');
  const regex = new RegExp(`(${pattern})`, 'gi');
  return text.replace(regex, (match) => `<b>${match}</b>`);
};
