import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from 'types';

const initialState: IRootState = {
  isLoggingOut: false,
  isUIVersionOutOfDate: false,
  navigationState: {},
  errorMessage: '',
  snackState: {
    open: false,
    isSuccess: false,
    message: ''
  }
};

const slice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },

    showSuccessToast(state, action) {
      state.snackState = {
        open: true,
        isSuccess: true,
        message: action.payload
      };
    },

    showFailureToast(state, action) {
      state.snackState = {
        open: true,
        isSuccess: false,
        message: action.payload
      };
    },

    clearToast(state) {
      state.snackState = {
        ...state.snackState,
        open: false
      };
    },

    resetRootState(state) {
      return {
        ...initialState
      };
    },

    setIsLoggingOut(state, action) {
      state.isLoggingOut = action.payload;
    },

    setIsUIVersionOutOfDate: (state, action) => {
      state.isUIVersionOutOfDate = action.payload;
    },

    setNavigationState: (state, action) => {
      state.navigationState = action.payload;
    }
  }
});

export default slice.reducer;
export const {
  setErrorMessage,
  showSuccessToast,
  showFailureToast,
  clearToast,
  resetRootState,
  setIsLoggingOut,
  setIsUIVersionOutOfDate,
  setNavigationState
} = slice.actions;
