import ConfirmationModal from './ConfirmationModal';
import { dispatch, setIsLoggingOut, useSelector } from 'store';
import { msalInstance } from 'contexts/Provider';

const LogoutModal: React.VFC = () => {
  const { isLoggingOut } = useSelector((state) => state.root);

  const cancelConfirmation = () => {
    dispatch(setIsLoggingOut(false));
  };

  const confirmLogout = () => {
    sessionStorage.clear();

    msalInstance.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
      }
    });
  };

  return (
    <ConfirmationModal
      open={isLoggingOut}
      title="Authentication - Logout"
      message="Are you sure you want to logout?"
      onCancel={cancelConfirmation}
      onConfirm={confirmLogout}
    />
  );
};

export default LogoutModal;
