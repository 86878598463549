import moment from 'moment';
import { ITask, SLA_STATUS } from 'types';
import { taskPriorityEnum, taskTypeEnum } from 'types/store/taskEnums';

export const calculateSLAStatus = (task: ITask | null) => {
  if (!task) {
    return SLA_STATUS.COMPLIANT;
  }

  const now = moment.utc();
  let slaDate = moment.utc(task.modifiedDate);

  if (task.taskTypeId === taskTypeEnum.Reminder && task.additionalFieldValues && task.additionalFieldValues?.length > 0) {
    const reminderDateField = task.additionalFieldValues.find((field) => field.fieldName === 'reminderDate');
    const momentReminderDate = moment.utc(reminderDateField?.fieldValue);
    if (momentReminderDate.isBefore(now)) {
      slaDate = momentReminderDate;
    }
  }

  const slaDifference = now.diff(slaDate, 'minutes');
  let slaStatus = SLA_STATUS.COMPLIANT;

  switch (task.taskPriorityId) {
    case taskPriorityEnum.Emergency:
      if (slaDifference >= 11) {
        slaStatus = SLA_STATUS.BREACH;
      } else if (slaDifference >= 8) {
        slaStatus = SLA_STATUS.WARNING;
      }
      break;
    case taskPriorityEnum.High:
      if (slaDifference >= 16) {
        slaStatus = SLA_STATUS.BREACH;
      } else if (slaDifference >= 10) {
        slaStatus = SLA_STATUS.WARNING;
      }
      break;
    case taskPriorityEnum.Routine:
      if (slaDifference >= 30) {
        slaStatus = SLA_STATUS.BREACH;
      } else if (slaDifference >= 25) {
        slaStatus = SLA_STATUS.WARNING;
      }
      break;
    default:
      slaStatus = SLA_STATUS.COMPLIANT;
      break;
  }

  return slaStatus;
};
