import { useSoundEffect } from '../packages/sound-effects';
import notificationSound from '../assets/notification-sound-7062.mp3';

//  Group all sounds here!
export const useSoundEffects = () => {
  const [invokeNotificationSound] = useSoundEffect(notificationSound);

  return {
    invokeNotificationSound
  };
};
