import React from 'react';
import { ApiService, ApiDataApiService } from 'services';

/*
    Return an instance of a service that uses ApiDataApiService

    Usage:

    const MyService {
        constructor (apiService: ApiService) {

        }
    }

    const MyComponent = () => {
        const myServiceInstance = useApiDataService<MyService>(MyService);
        return null;
    }
*/

export const useApiDataService = <ServiceType>(type: { new (s: ApiService): ServiceType }) =>
  React.useMemo(() => new type(ApiDataApiService.create()), [type]);

export default useApiDataService;
