import { ITask } from 'types';
import { taskPriorityEnum, taskStatusEnum } from 'types/store/taskEnums';

const isTaskStatusWithFilters =
  (
    taskStatus: taskStatusEnum,
    priorityStatus: taskPriorityEnum,
    useSmsFilter: boolean,
    useEmailFilter: boolean,
    useReplyFilter: boolean,
    useUnassignedFilter: boolean
  ) =>
  (task: ITask) =>
    task.taskStatusId === taskStatus &&
    task.taskPriorityId === priorityStatus &&
    (useSmsFilter && useEmailFilter
      ? task.isSmsTask || task.isEmailTask
      : (!useSmsFilter || task.isSmsTask) && (!useEmailFilter || task.isEmailTask)) &&
    (!useReplyFilter || task.hasNewReply) &&
    (!useUnassignedFilter || !task.assignedToUserId);

//  task counts generation
export const findStatusTaskCounts = (
  tasks: ITask[],
  taskStatus: taskStatusEnum,
  useSmsFilter: boolean,
  useEmailFilter: boolean,
  useReplyFilter: boolean,
  useUnassignedFilter: boolean
) =>
  !Boolean(tasks.length)
    ? [0, 0, 0]
    : [
        tasks.filter(
          isTaskStatusWithFilters(taskStatus, taskPriorityEnum.Routine, useSmsFilter, useEmailFilter, useReplyFilter, useUnassignedFilter)
        ).length,
        tasks.filter(
          isTaskStatusWithFilters(taskStatus, taskPriorityEnum.High, useSmsFilter, useEmailFilter, useReplyFilter, useUnassignedFilter)
        ).length,
        tasks.filter(
          isTaskStatusWithFilters(taskStatus, taskPriorityEnum.Emergency, useSmsFilter, useEmailFilter, useReplyFilter, useUnassignedFilter)
        ).length
      ];
