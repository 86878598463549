import { datadogRum } from '@datadog/browser-rum';
import { loggedInUser } from 'hooks';
import { useEffect } from 'react';

export const DataDogUser: React.VFC = () => {
  const { isLoggedIn, userId, userName, userEmail } = loggedInUser.useController();

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const user = {
      id: userId,
      name: userName,
      email: userEmail
    };

    datadogRum.setUser(user);
  }, [isLoggedIn]);

  return null;
};
