import React, { useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { GuardProps } from 'types/auth';
import { useSelector } from 'store';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps): ReactElement | null => {
  const isLoggedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const { navigationState } = useSelector((state) => state.root);

  useEffect(() => {
    if (isLoggedIn && navigationState.taskId && !navigationState.memberId) {
      navigate('/task', {
        replace: true,
        state: {
          paramsData: navigationState
        }
      });
    } else if (isLoggedIn && navigationState.memberId) {
      navigate('/member', {
        replace: true,
        state: {
          paramsData: navigationState
        }
      });
    } else if (isLoggedIn) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigationState, isLoggedIn, navigate]);

  if (React.isValidElement(children)) {
    return <>{children}</>;
  }

  return null;
};

export default GuestGuard;
