import { useState } from 'react';
import moment, { Moment } from 'moment';
import { LocalizationProvider, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface MomentDatePickerProps {
  showTime?: boolean;
  onSelect: (selectedValue: string | undefined) => void;
  currentValue: string | null;
  label?: string | undefined;
  name?: string | undefined;
  helperText?: string | undefined;
  error?: boolean | undefined;
  isDisabled?: boolean | undefined;
  isRequired?: boolean | undefined;
}

export const MomentDatePicker: React.VFC<MomentDatePickerProps> = (props) => {
  const { showTime, currentValue, label, onSelect, helperText, error, isDisabled, isRequired } = props;
  const [value, setValue] = useState<Moment | null>(moment(currentValue));

  const pickerProps = {
    disablePast: true,
    disabled: isDisabled,
    label,
    value,
    onChange: (newValue: Moment | null) => {
      setValue(newValue);
      onSelect(newValue?.format());
    },
    slotProps: {
      textField: {
        helperText: helperText,
        error: error ? error : false,
        required: isRequired
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {showTime ? <DateTimePicker {...pickerProps} /> : <DatePicker {...pickerProps} />}
    </LocalizationProvider>
  );
};
