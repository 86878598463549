import { Stack, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import activityContext from '../../use-recent-activity';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

export const SearchActivity = () => {
  const { searchString, handleSearchString } = activityContext.useController();

  return (
    <TextField
      id="helper-text-basic"
      value={searchString}
      onChange={(e) => handleSearchString(e.target.value)}
      style={{ width: '100%' }}
      placeholder="Search Activity"
      helperText={searchString.length >= 1 && searchString.length < 3 ? 'Continue typing to search...' : undefined}
      InputProps={{
        endAdornment: (
          <Stack direction="row" spacing={2}>
            <ClearRoundedIcon
              onClick={() => handleSearchString('')}
              sx={{ display: Boolean(searchString.length) ? 'inline-block' : 'none', cursor: 'pointer' }}
            />
            <SearchIcon />
          </Stack>
        )
      }}
    />
  );
};
