import { useDebouncedState } from 'sollishealth.core.react';
import { createContextForController } from 'react-controller-context';

const useController = () => {
  const [debouncedSearchString, setSearchString, searchString] = useDebouncedState('');
  return { debouncedSearchString, setSearchString, searchString };
};

const context = createContextForController(useController);

export const SearchStringProvider = context.Provider;
export const useSearchString = context.useController;
