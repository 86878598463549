import { Button, Grid } from '@mui/material';
import DownloadAllButton from './DownloadAllButton';
import { usePreviewProvider } from './use-preview-provider';

const FileViewer = () => {
  const { clearPreview, handleFileDownload } = usePreviewProvider();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={4} style={{ textAlign: 'left' }}>
        <DownloadAllButton />
      </Grid>
      <Grid item xs={8} paddingLeft={1} style={{ textAlign: 'right' }}>
        <Button
          style={{ display: 'inline-block' }}
          variant="contained"
          sx={{ margin: '10px', backgroundColor: '#0958d9', color: '#ffffff' }}
          type="button"
          onClick={() => handleFileDownload()}
        >
          Download
        </Button>
        <Button
          style={{ display: 'inline-block' }}
          variant="contained"
          sx={{ margin: '10px', backgroundColor: '#9AA4B2', color: '#ffffff' }}
          type="button"
          onClick={clearPreview}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default FileViewer;
