// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| CUSTOM FUNCTION - COLOR SHADOWS ||============================== //

const getShadow = (theme: Theme, shadow: string) => {
  const customShadows = theme.customShadows || {};

  switch (shadow) {
    case 'secondary':
      return customShadows.secondary;
    case 'error':
      return customShadows.error;
    case 'warning':
      return customShadows.warning;
    case 'info':
      return customShadows.info;
    case 'success':
      return customShadows.success;
    case 'primaryButton':
      return customShadows.primaryButton;
    case 'secondaryButton':
      return customShadows.secondaryButton;
    case 'errorButton':
      return customShadows.errorButton;
    case 'warningButton':
      return customShadows.warningButton;
    case 'infoButton':
      return customShadows.infoButton;
    case 'successButton':
      return customShadows.successButton;
    default:
      return customShadows.primary;
  }
};

export default getShadow;
