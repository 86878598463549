import React from 'react';

/*
    Similar to useEffect except it will not run the callback on first render.  only when deps change
*/
export const useOnUpdateEffect = (func: React.EffectCallback, deps: React.DependencyList | undefined) => {
  const didMount = React.useRef(false);

  React.useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
