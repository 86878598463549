import React from 'react';
import { Grid, Stack } from '@mui/material';
import SearchToggleButton from './SearchToggleButton';
import GlobalSearchSelector from './GlobalSearchSelector';

const GlobalSearch: React.VFC = () => {
  return (
    <Stack style={{ backgroundColor: '#ffffff' }}>
      <Stack direction="row" alignItems="center">
        <Grid style={{ width: 375 }}>
          <GlobalSearchSelector />
        </Grid>
        <SearchToggleButton />
      </Stack>
    </Stack>
  );
};

export default GlobalSearch;
