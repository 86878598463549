import { dispatch, resetMemberState } from 'store';
import { resetRootState } from './root.slice';

export const root_clearAllStates = () => {
  dispatch(resetMemberState());
  dispatch(resetRootState());

  sessionStorage.clear();
  localStorage.clear();
};
