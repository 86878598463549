import MainCard from 'components/MainCard';
import { CardContent, Grid, Stack, Typography } from '@mui/material';
import dashboardContext, { DASHBOARD_SUMMARY_FILTER } from 'views/Dashboard/useDashboardContext';
import InProgressStatusFilter from './Charts/InProgressStatusFilter';
import NewStatusFilter from './Charts/NewStatusFilter';
import { loggedInUser } from 'hooks';
import ReloadTasks from './ReloadTasks';
import TextHighlight, { highlightColorEnum } from 'components/TextHighlight';
import { EmailFilter, ReplyFilter, SmsFilter, UnassignedFilter } from './Buttons/FilterButtons';

const TaskFilters: React.VFC = () => {
  const { hasTeams } = loggedInUser.useController();
  const { hasTasks, summaryFilter, isTasksLoading, hasSummaryTasks } = dashboardContext.useController();

  if (!hasTeams) {
    return null;
  }

  if (!hasTasks || (summaryFilter && !hasSummaryTasks)) {
    return (
      <CardContent>
        <Grid item xs={12} style={{ height: '100%', minHeight: '375px' }}>
          <Typography variant="h5" textAlign="center" marginTop="100px">
            {isTasksLoading ? 'Loading Dashboard...' : 'No tasks found'}
          </Typography>
        </Grid>
      </CardContent>
    );
  }

  return (
    <MainCard
      contentSX={{ p: 2.25 }}
      title={
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            <Typography variant="h5">Quick Filters</Typography>
          </Grid>
          <Grid item xs="auto" textAlign="right">
            <ReloadTasks />
          </Grid>
        </Grid>
      }
    >
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-evenly' }}>
            <SmsFilter />
            <EmailFilter />
            <ReplyFilter />
            {summaryFilter === DASHBOARD_SUMMARY_FILTER.TEAM && <UnassignedFilter />}
          </Stack>
        </Grid>
        <Grid item xs={6} textAlign="center">
          <NewStatusFilter />
        </Grid>
        <Grid item xs={6} textAlign="center">
          <InProgressStatusFilter />
        </Grid>
        <Stack spacing={1} sx={{ width: '100%', alignItems: 'center', cursor: 'default' }}>
          <Typography variant="h6">Task Priority Color Key</Typography>
          <Stack direction="row" spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
            <TextHighlight text="Routine" highlightColor={highlightColorEnum.Routine} height="30px" fontVariant="h6" />
            <TextHighlight text="High" highlightColor={highlightColorEnum.High} height="30px" fontVariant="h6" />
            <TextHighlight text="Emergency" highlightColor={highlightColorEnum.Emergency} height="30px" fontVariant="h6" />
          </Stack>
        </Stack>
      </Grid>
    </MainCard>
  );
};

export default TaskFilters;
