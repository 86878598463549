import { Stack, Typography } from '@mui/material';
import ApexSLAChart from './ApexPriorityChart';
import EmptyFilter from './EmptyFilter';
import taskFiltersContext from '../../../use-task-filters';
import { taskStatusEnum } from 'types/store/taskEnums';

const NewStatusFilter: React.VFC = () => {
  const { newRedrawKey, hasNewTasks, newCounts, handlePriorityFilter } = taskFiltersContext.useController();

  if (!hasNewTasks) {
    return <EmptyFilter label="New Tasks" />;
  }

  return (
    <Stack data-testid={'member-filter-vip'}>
      <Typography variant="h5">New Tasks</Typography>
      <ApexSLAChart
        key={`newFilter${newRedrawKey}`}
        priorityCounts={newCounts}
        onPrioritySelected={(p) => handlePriorityFilter(taskStatusEnum.New, p)}
      />
    </Stack>
  );
};

export default NewStatusFilter;
