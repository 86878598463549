import { Box, Chip, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import _ from 'lodash';

const useFilterOptions = (currentChoices: string[], allowMultiples: boolean, onChange: (o: string[]) => void) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const optionFontWeight = theme.typography.fontWeightRegular;
  const selectedOptionFontWeight = theme.typography.fontWeightBold;

  const handleFilterChoice = (choice: string) => {
    const existingChoices = [...currentChoices];
    const choiceIndex = existingChoices.indexOf(choice);

    if (choiceIndex !== -1) {
      existingChoices.splice(choiceIndex, 1);
    } else {
      existingChoices.unshift(choice);
    }

    onChange(_.orderBy(existingChoices));

    if (!allowMultiples) {
      hideOptions();
    }
  };

  const clearFilter = () => {
    onChange([]);
    setAnchorEl(null);
  };

  const showOptions = (event: any | null) => {
    setAnchorEl(event.currentTarget);
  };

  const hideOptions = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    optionFontWeight,
    selectedOptionFontWeight,
    handleFilterChoice,
    clearFilter,
    showOptions,
    hideOptions
  };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuSlotProps = {
  paper: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface FilterOptionsSelectProps {
  currentChoices: string[];
  filterName: string;
  allowMultiples?: boolean;
  filterOptions: FilterSelectOption[];
  onChange: (o: string[]) => void;
}

export interface FilterSelectOption {
  id: string;
  displayName: string;
}

export const FilterOptionsSelect = (props: FilterOptionsSelectProps) => {
  const { currentChoices, filterName, allowMultiples = false, filterOptions, onChange } = props;
  const { optionFontWeight, selectedOptionFontWeight, anchorEl, handleFilterChoice, clearFilter, showOptions, hideOptions } =
    useFilterOptions(currentChoices, allowMultiples, onChange);

  return (
    <Box style={{ flex: 1, position: 'relative' }}>
      <Chip
        size={Boolean(currentChoices.length) ? 'medium' : 'small'}
        variant={Boolean(currentChoices.length) ? 'outlined' : undefined}
        label={filterName}
        icon={Boolean(currentChoices.length) ? <FilterAltRoundedIcon /> : <FilterAltOffRoundedIcon />}
        onClick={(e) => (Boolean(anchorEl) ? hideOptions() : showOptions(e))}
        onDelete={Boolean(currentChoices.length) ? () => clearFilter() : undefined}
        deleteIcon={Boolean(currentChoices.length) ? <ClearRoundedIcon /> : undefined}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={hideOptions}
        MenuListProps={{ onMouseLeave: hideOptions }}
        slotProps={MenuSlotProps}
        sx={{ zIndex: 2001 }}
      >
        {filterOptions.map((option, index) => (
          <MenuItem
            key={index}
            value={option.id}
            selected={currentChoices.includes(option.id)}
            style={{
              fontWeight: currentChoices.includes(option.id) ? selectedOptionFontWeight : optionFontWeight
            }}
            onClick={() => handleFilterChoice(option.id)}
          >
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
