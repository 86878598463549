import React from 'react';
import { AssignToControllerContext } from './useAssignToProvider';
import useAssignToController, { AssignToControllerProps } from './useAssignToController';

export interface AssignToProviderProps extends AssignToControllerProps {
  children: React.ReactNode;
}

export const AssignToProvider = (props: AssignToProviderProps) => {
  const controller = useAssignToController(props);
  return <AssignToControllerContext.Provider value={controller}>{props.children}</AssignToControllerContext.Provider>;
};

export default AssignToProvider;
